import BorderColorIcon from "@mui/icons-material/BorderColor";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  List,
  Checkbox,
  Collapse,
  FormControlLabel,
  IconButton,
  ListItem,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import LensContext from "./utils/LensContext";
import MoreOptions from "./Buttons/MoreOptions";

const ExpandTrigger = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TruncatedTypography = styled(Typography)({
  overflow: "hidden",
  whiteSpace: "nowrap",
  fontSize: "0.8rem",
  lineHeight: "1.2",
  textOverflow: "ellipsis",
  maxWidth: "134px", // Adjust this value as needed
});

export default function CollapsibleCheckbox({
  label,
  onChange,
  childs,
  defaultCollapsed = false,
}) {
  const [expanded, setExpanded] = React.useState(!defaultCollapsed);
  const { selectedLenses, updateSelectedLenses, deleteLens } =
    useContext(LensContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedLens, setSelectedLens] = useState(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  // This function toggles the selection status of a lens
  const handleChildCheckChange = (childLabel, event) => {
    const isChecked = event.target.checked;
    updateSelectedLenses(childLabel, isChecked);
  };

  const handleDeleteClick = (lens) => {
    setSelectedLens(lens);
    setOpenDialog(true);
  };

  const handleEditClick = (lens) => {
    navigate(`/lenses/edit/${lens.lensId}`);
  };

  const handleConfirmDelete = () => {
    deleteLens(selectedLens.lensId);
    setOpenDialog(false);
    setSelectedLens(null);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setSelectedLens(null);
  };

  return (
    <>
      <ListItem
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBotton: "0px",
        }}
        className={`collapsible-list-item ${
          childs && childs.length > 0 ? "has-childs" : ""
        }`}
      >
        <Typography variant="body1" sx={{ flexGrow: 1 }}>
          {label}
        </Typography>
        {childs && childs.length > 0 && (
          <ExpandTrigger
            expand={expanded}
            aria-expanded={expanded}
            aria-label="show more"
            onClick={handleToggle}
            sx={{ marginLeft: "auto" }}
          >
            <KeyboardArrowDownOutlinedIcon />
          </ExpandTrigger>
        )}
        {!childs && (
          <IconButton>
            <BorderColorIcon />
          </IconButton>
        )}
      </ListItem>
      {childs && childs.length > 0 && (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List
            sx={{
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
          >
            {childs.map((child, i) => (
              <ListItem
                key={child.uniqueId || i}
                sx={{
                  display: "flex",
                  paddingBottom: "0px",
                  paddingTop: "0px", // Reduced padding-top
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedLenses.includes(child.lensIdentifier)}
                      onChange={(event) =>
                        handleChildCheckChange(child.lensIdentifier, event)
                      }
                      name={child.label}
                    />
                  }
                  label={
                    <TruncatedTypography>{child.label}</TruncatedTypography>
                  } // Use truncated text
                  sx={{ marginRight: 0 }}
                />
                <MoreOptions
                  handleDeleteClick={handleDeleteClick}
                  handleEditClick={handleEditClick}
                  child={child}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}

      <Dialog
        open={openDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the lens "{selectedLens?.label}"?
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
