import CameraIcon from "@mui/icons-material/Camera";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Skeleton,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import "../styles/components/side-drawer.scss";
import CollapsibleCheckbox from "./CollapsibleCheckbox";
import { useChatContext } from "./utils/ChatContext";
import { useAuth0 } from "@auth0/auth0-react";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import React, { useContext, useEffect, useMemo, useState } from "react";
import "../styles/components/side-drawer.scss";
import ApiUtils from "./utils/ApiUtils";
import { SessionUtils } from "./utils/SessionUtils";
import LabelEditableListItem from "./LabelEditableListItem";
import {
  useBrainstormValue,
  useSetBrainstormValue,
} from "./utils/BrainstormContext";
import LensContext from "./utils/LensContext";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function ChatSettings({ open, onClose }) {
  const navigate = useNavigate();
  const [lensesExpanded, setLensesExpanded] = React.useState(true);
  const [chatHistory, setChatHistory] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const { user, getAccessTokenSilently, error, isAuthenticated } = useAuth0();
  const rowsPerPage = 5;
  const totalItems = useMemo(
    () => chatHistory.reduce((total, group) => total + group.items.length, 0),
    [chatHistory]
  );
  const totalPages = Math.ceil(totalItems / rowsPerPage);
  const startIndex = currentPage * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const itemsToDisplay = SessionUtils.paginateItems(
    chatHistory,
    startIndex,
    endIndex
  );
  const brainstormValue = useBrainstormValue();
  const setBrainstormValue = useSetBrainstormValue();
  const handleBrainstormChange = (event, newValue) => {
    setBrainstormValue(newValue);
  };
  const { lenses, updateSelectedLenses, setLenses, setAccessToken, setUserId } =
    useContext(LensContext);
  const { loadChatSession, isNewSession } = useChatContext();

  useEffect(() => {
    const fetchData = async () => {
      if (!user) {
        return;
      }
      try {
        const accessToken = await getAccessTokenSilently();
        const [chatHistoryResponse, lensesResponse] = await Promise.all([
          ApiUtils.fetchChatHistoryList(user, accessToken),
          ApiUtils.getLenses(user.sub, accessToken),
        ]);
        const formattedChatHistory = SessionUtils.formatSessionListData(
          chatHistoryResponse.sessions
        );
        setChatHistory(formattedChatHistory);
        setLenses(lensesResponse || []);
        setAccessToken(accessToken);
        setUserId(user.sub);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [getAccessTokenSilently, user, setLenses, setAccessToken, setUserId]);

  const handleLensesToggle = () => {
    setLensesExpanded(!lensesExpanded);
  };
  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleSessionClick = (item) => {
    getAccessTokenSilently()
      .then((accessToken) => {
        loadChatSession(item.sessionId, accessToken);
        handleClose();
      })
      .catch((error) => {
        console.error("Error getting access token");
        // Handle the error, e.g., show an error message to the user
      });
  };

  const handleCheckChange = (wordOrPhrase, isChecked) => {};
  const handleShiftDown = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 5 / rowsPerPage, totalPages - 1)
    );
  };
  const handleShiftUp = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleOnChangeChatTitle = (item, value) => {};

  // Group lenses by lens_type
  const groupedLenses = (lenses || []).reduce((acc, lens) => {
    const lensType = lens.lens_type;
    if (
      lensType === "Definition" ||
      lensType === "Organization Goals and Strategies" ||
      lensType === "Priority Audience" ||
      lensType === "Persona" ||
      lensType === "Task Orientation" ||
      lensType === "Context Stories"
    ) {
      if (!acc[lensType]) {
        acc[lensType] = [];
      }
      acc[lensType].push({
        label:
          lens.word_or_phrase ||
          lens.lensName ||
          lens.audienceName ||
          lens.personaName,
        lensType,
        lensIdentifier:
          lens.word_or_phrase ||
          lens.lensName ||
          lens.audienceName ||
          lens.personaName,
        lensId: lens.lensId,
      });
    }
    return acc;
  }, {});
  const getLensLabel = (lensType) => {
    switch (lensType) {
      case "Definition":
        return "Definition";
      case "Organization Goals and Strategies":
        return "Org Goals and Strategies";
      case "Priority Audience":
        return "Priority Audience";
      case "Persona":
        return "Persona";
      case "Task Orientation":
        return "Task Orientation";
      case "Context Stories":
        return "Context Stories";
      default:
        return lensType;
    }
  };

  return (
    <>
      <ListItem disablePadding sx={{ display: "block" }}>
        <ListItemButton
          onClick={handleLensesToggle}
          sx={{
            minHeight: 48,
            justifyContent: open ? "initial" : "center",
            px: 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? 1.5 : "auto",
              justifyContent: "center",
            }}
          >
            <CameraIcon />
          </ListItemIcon>
          <ListItemText sx={{ opacity: open ? 1 : 0 }}>
            Lenses{" "}
            <Tooltip
              placement="right"
              title="Customizable filters that provide additional context to your query for AI PRIORI. Check the box next to a lens(es) to have AI PRIORI take it into consideration when generating a response."
              arrow
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </ListItemText>
          <ListItemIcon
            sx={{
              minWidth: 0,
              display: open ? "initial" : "none",
              justifyContent: "center",
            }}
          >
            <ExpandMore
              expand={lensesExpanded}
              aria-expanded={lensesExpanded}
              aria-label="show more"
              sx={{
                minWidth: 0,
                display: open ? "initial" : "none",
                justifyContent: "center",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
        <Collapse in={lensesExpanded && open} timeout="auto" unmountOnExit>
          <Box>
            <List>
              {Object.entries(groupedLenses).map(([lensType, lenses]) => (
                <CollapsibleCheckbox
                  key={lensType}
                  label={getLensLabel(lensType)}
                  childs={lenses}
                  onChange={(lensIdentifier, isChecked) =>
                    updateSelectedLenses(lensIdentifier, isChecked)
                  }
                />
              ))}
            </List>
            {/** page down disabled 
            <div className="collapse-all">
              <IconButton>
                <KeyboardArrowDownOutlinedIcon />
              </IconButton>
            </div>
                */}
            {/** 
            <Button variant="text">Clear All</Button>
            <Button
              variant="text"
              onClick={() => {
                navigate("/chat/lens/create");
                handleClose();
              }}
            >
              Create new lenses
            </Button>
            */}
          </Box>
        </Collapse>
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1.5 : "auto",
            justifyContent: "center",
          }}
        >
          <FlashOnIcon />
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }}>
          Brainstorm Power{" "}
          <Tooltip
            placement="right"
            title="Set the level of creativity and brainstorming you want AI PRIORI to use when generating its response. 0 = Only user content; 5 = Minimal creativity; 10 = Enhanced creativity"
            arrow
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </ListItemText>
      </ListItem>
      <Box sx={{ padding: "0 35px", display: open ? "inherit" : "none" }}>
        <Slider
          aria-label="Brainstorm Power"
          value={brainstormValue * 10}
          onChange={(event, newValue) =>
            handleBrainstormChange(event, newValue / 10)
          }
          step={1}
          min={1}
          max={10}
          valueLabelDisplay="auto"
          marks={[
            {
              value: 1,
              label: "Min",
            },
            {
              value: 10,
              label: "Max",
            },
          ]}
        />
      </Box>
      <Divider />
      <ListItem>
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 1.5 : "auto",
            justifyContent: "center",
          }}
        >
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText sx={{ opacity: open ? 1 : 0 }}>
          Chat History{" "}
          <Tooltip
            placement="right"
            title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed metus velit."
            arrow
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </ListItemText>
      </ListItem>
      {isLoading ? (
        <>
          <Box sx={{ padding: "16px" }}>
            <div className="loading-state">
              <CircularProgress
                sx={{ width: "24px", display: "block", marginBottom: 2 }}
                size="small"
              />
              Loading your chat history...
            </div>
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          </Box>
        </>
      ) : (
        <>
          <List
            className="chat-history"
            style={{ display: open ? "flex" : "none" }}
          >
            <div className="collapse-all">
              {currentPage !== 0 && (
                <IconButton onClick={handleShiftUp}>
                  <KeyboardArrowUpOutlinedIcon />
                </IconButton>
              )}
            </div>
            {itemsToDisplay.map((group, index) => (
              <div key={`group-${index}`}>
                <ListItem>
                  <Typography variant="h4">{group.groupLabel}</Typography>
                </ListItem>
                {group.items.map((session, subIndex) => {
                  return (
                    <LabelEditableListItem
                      key={`session-${subIndex}`}
                      onClick={() => handleSessionClick(session)}
                      onChange={handleOnChangeChatTitle}
                      item={session}
                    />
                  );
                })}
              </div>
            ))}
            <div className="collapse-all">
              <IconButton
                onClick={handleShiftDown}
                disabled={currentPage >= totalPages - 1}
              >
                <KeyboardArrowDownOutlinedIcon />
              </IconButton>
            </div>
          </List>
        </>
      )}
    </>
  );
}
