import React from "react";
import { FormControl, InputLabel, TextField, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PersonaLens = ({
  personaName,
  setPersonaName,
  personaDescription,
  setPersonaDescription,
  personaBackground,
  setPersonaBackground,
  personaCharacteristics,
  setPersonaCharacteristics,
  personaValues,
  setPersonaValues,
  communicationStyle,
  setCommunicationStyle,
  scenarioContext,
  setScenarioContext,
  personaDialogue,
  setPersonaDialogue,
  activeStep,
  nextStep,
  prevStep,
  onFinish,
  isEditing = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {activeStep === 1 && (
        <>
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <p>
              Create a Persona Lens to generate an answer that considers and
              takes on the voice, characteristics, perspective and persona that
              you have identified.
            </p>
            <Typography className="footer-message">
              <p>Please note:</p>
              <p>
                Efficacy of Lenses is dependent on the information used to
                create the lens as well as the subsequent content and questions
                it is used in conjunction with. Lenses are not guaranteed to
                change AI PRIORI's responses.
              </p>
            </Typography>
            <div className="persona">
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="persona-name-input">
                  Persona Name:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <TextField
                  required
                  placeholder="Write here"
                  id="persona-name-input"
                  value={personaName}
                  onChange={(e) => setPersonaName(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="persona-description-input">
                  Persona Description:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  What persona do you want AI PRIORI to take on when generating
                  a response to your query? (e.g., CEO of a philanthropic
                  foundation, mentor, third grade teacher at local public
                  school, Executive Director of the local food bank, Volunteer
                  at the animal shelter, caregiver to their aging parents, young
                  mother recently diagnosed with diabetes, father of three
                  children, 24-year-old looking for employment)
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="persona-description-input"
                  value={personaDescription}
                  onChange={(e) => setPersonaDescription(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="persona-background-input">
                  Persona Background:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  What is the background of the persona? (e.g., Someone in their
                  40-50's, a professional who resides in a suburban
                  neighborhood. Caregiver is the primary caregiver for her aging
                  parents, who live with them in their family home. The
                  caregiver's parents are in their late 70s and face health
                  challenges that require constant attention and support.
                  Caregiver is an only child and feels a deep sense of
                  responsibility for their parents' well-being. Caregiver also
                  has a demanding full-time job as a marketing manager, and
                  their life revolves around balancing their career with
                  providing love and care for their parents.)
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="persona-background-input"
                  value={personaBackground}
                  onChange={(e) => setPersonaBackground(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="actions" style={{ marginTop: "20px" }}>
              {isEditing ? (
                <>
                  <Button onClick={() => navigate("/chat")}>Cancel</Button>
                </>
              ) : (
                <>
                  <Button onClick={prevStep}>Back</Button>
                </>
              )}
              <Button variant="contained" onClick={nextStep}>
                Next
              </Button>
            </div>
          </div>
        </>
      )}
      {activeStep === 2 && (
        <>
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <div className="persona">
              <FormControl className="top-labeled">
                <InputLabel shrink htmlFor="persona-characteristics-input">
                  Persona Characteristics:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  List a few of the characteristics of this persona's: (e.g.,
                  Compassionate – always helping others, Resilient – finds ways
                  to thrive despite the heavy stress of serving as a caregiver,
                  Organized – created meticulous schedules and medication
                  histories)
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="persona-characteristics-input"
                  value={personaCharacteristics}
                  onChange={(e) => setPersonaCharacteristics(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="persona-values-input">
                  Persona Values:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  List several of values you associate with this persona: (e.g.,
                  Family First – prioritizes her family above all else, Empathy
                  and Compassion – these are guiding principles in her life,
                  Independence and Dignity – strives to create an environment
                  where her parents can maintain their own sense of self-worth
                  and dignity as they age.)
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="persona-values-input"
                  value={personaValues}
                  onChange={(e) => setPersonaValues(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="communication-style-input">
                  Communication Style:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  What is the communication style of this persona? (e.g., The
                  caregiver's communication style is characterized by empathy,
                  patience, and clarity. Given their role as the primary
                  caregiver for aging parents, they adapt their communication to
                  ensure effective and compassionate interactions.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="communication-style-input"
                  value={communicationStyle}
                  onChange={(e) => setCommunicationStyle(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="scenario-context-input">
                  Scenario/Context:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  Provide a scenario or context for the persona.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="scenario-context-input"
                  value={scenarioContext}
                  onChange={(e) => setScenarioContext(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="persona-dialogue-input">
                  Persona Dialogue:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  If you have sample dialogue or a document that they have
                  drafted you can share excerpts here to help AI PRIORI better
                  understand their tone, style, and preferred word choice.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="persona-dialogue-input"
                  value={personaDialogue}
                  onChange={(e) => setPersonaDialogue(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="actions" style={{ marginTop: "20px" }}>
              <Button onClick={prevStep}>Back</Button>
              <Button variant="contained" onClick={onFinish}>
                {isEditing ? "Update" : "Create"} lens
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PersonaLens;
