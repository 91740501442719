import React from "react";
import { FormControl, InputLabel, TextField, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const TaskOrientationLens = ({
  lensName,
  setLensName,
  taskDefinition,
  setTaskDefinition,
  desiredOutcome,
  setDesiredOutcome,
  challenges,
  setChallenges,
  peopleInvolved,
  setPeopleInvolved,
  activeStep,
  nextStep,
  prevStep,
  onFinish,
  isEditing = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {activeStep === 1 && (
        <>
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <p>
              Apply this lens when you have a task or ask of AI PRIORI that
              involves specific parameters. This is where you can outline those
              parameters so they will be taken into consideration when
              generating a response.
            </p>
            <Typography className="footer-message">
              <p>Please note:</p>
              <p>
                Efficacy of Lenses is dependent on the information used to
                create the lens as well as the subsequent content and questions
                it is used in conjunction with. Lenses are not guaranteed to
                change AI PRIORI's responses.
              </p>
            </Typography>
            <div className="task-orientation">
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="lens-name-input">
                  Lens Name:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <TextField
                  required
                  placeholder="Write here"
                  id="lens-name-input"
                  value={lensName}
                  onChange={(e) => setLensName(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="task-definition-input">
                  Task Definition:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  Name and describe the task you want taken into consideration
                  alongside your question:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Example: I'm working on a project to improve my nonprofit's
                  donor support services and procedures. We want to streamline
                  our response times for donor inquiries.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="task-definition-input"
                  value={taskDefinition}
                  onChange={(e) => setTaskDefinition(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="desired-outcome-input">
                  Desired Outcome:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  What is your desired outcome if this task were to be
                  completed?
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Example: We're aiming to reduce response times by 30% within
                  the next quarter.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="desired-outcome-input"
                  value={desiredOutcome}
                  onChange={(e) => setDesiredOutcome(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="actions" style={{ marginTop: "20px" }}>
              {isEditing ? (
                <>
                  <Button onClick={() => navigate("/chat")}>Cancel</Button>
                </>
              ) : (
                <>
                  <Button onClick={prevStep}>Back</Button>
                </>
              )}
              <Button variant="contained" onClick={nextStep}>
                Next
              </Button>
            </div>
          </div>
        </>
      )}
      {activeStep === 2 && (
        <>
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <div className="task-orientation">
              <FormControl className="top-labeled">
                <InputLabel shrink htmlFor="challenges-input">
                  Challenges:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  Describe the challenge(s) or obstacle(s) you are facing in
                  relation to the task:
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Example: I am unsure how to set up an efficient donor support
                  ticketing system; I am looking for software recommendations; I
                  need the best practices for how to streamline ticketing
                  systems.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="challenges-input"
                  value={challenges}
                  onChange={(e) => setChallenges(e.target.value)}
                />
              </FormControl>
              <FormControl
                className="top-labeled"
                style={{ marginTop: "20px" }}
              >
                <InputLabel shrink htmlFor="people-involved-input">
                  People Involved:
                  {/*<span className="required">*</span>*/}
                </InputLabel>
                <Typography variant="body2" gutterBottom>
                  Who is involved with this task?
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Example: I am a team of 1 and therefore have constraints on
                  bandwidth – I do not have additional resources enabling me to
                  hire other team members.
                </Typography>
                <TextField
                  multiline
                  required
                  rows={4}
                  placeholder="Write here"
                  id="people-involved-input"
                  value={peopleInvolved}
                  onChange={(e) => setPeopleInvolved(e.target.value)}
                />
              </FormControl>
            </div>
            <div className="actions" style={{ marginTop: "20px" }}>
              <Button onClick={prevStep}>Back</Button>
              <Button variant="contained" onClick={onFinish}>
                {isEditing ? "Update" : "Create"} lens
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TaskOrientationLens;
