import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Box, Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate, useParams } from 'react-router-dom';
import lensesData from "../data/lenses_feed_example.json";
import AppLayout from "../layouts/AppLayout";
import "../styles/LensView.scss";
import { SelectBox } from '../components/Menus/SelectBox';

const lensTest = {
  title: 'This is the title of the Lens, which can vary in length but the design should adjust to it doesn’t matter how long it is. ',
  excerpt: 'This is a small description of what this lens should be used for. Nothing to complicated and straight to the point. Nonetheless, the users are always pushing the boundaries of the product, so we have to make sure the layout doesn’t break when the description of the lens exceeds a certain amount of lines. You can always truncate the text beyond a point that you think is correct.',
  authors: 'Emily Yu, Bryan Speelman + 4',
  published: 'March 12th, 2024',
  files: 12,
  id: '1234',
  version: '2.2',
  files: [
    'Healthy Housing.doc',
    'FairHousing2023.pdf',
    'CollaborationHousin...',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
  ]
}
const lenses = [
  lensTest,
  lensTest,
  lensTest,
  lensTest,
  lensTest
]

const LensRequestReview = () => {
  const navigate = useNavigate();
  const { lensId } = useParams();
  const lens = lensesData.find((lens) => lens.id === lensId);
  const tag = "personal";

  const {
    title,
    excerpt,
    authors,
    published,
    files,
    type,
    id,
    version,
  } = lens;

  const TruncatedTypography = styled(Typography)({
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.9rem",
    lineHeight: "1.2",
    textOverflow: "ellipsis",
    maxWidth: "113px",
  });
  const addToLibrary = () => {

  }
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (lens) => {
    setOpenDialog(true);
  };

  const formatDateToReadable = (dateString) => {
    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return formattedDate.replace(/(\d+)(,)/, `$1${daySuffix(day)},`);
  }
  return (
    <AppLayout contentWithTitle>
      <section className="lens-view">
        <Box sx={{ bgcolor: "background.paper" }} className="lenses-container">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
            <Button startIcon={<ChevronLeftIcon />} onClick={() => navigate('/lenses/review')}>
              Go Back
            </Button>
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Button startIcon={<RemoveRedEyeIcon />}>
                Try Lens
              </Button>
              <Button variant="contained" color="error" startIcon={<CloseIcon />} onClick={handleOpenDialog}>
                Deny
              </Button>
              <Button variant="contained" startIcon={<CheckIcon />}>
                Approve
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box className="lens-container">
            <Typography component="h1">
              {title}
            </Typography>
            <Box className="lens-info">
              <Typography>
                <PeopleOutlineIcon /> {authors}
              </Typography>
              <Typography>
                <CalendarTodayIcon /> published {formatDateToReadable(published)}
              </Typography>
              <Typography>
                <FolderOpenIcon /> {files.length} file{files.length > 1 ? 's' : ''}
              </Typography>
            </Box>
            <Box className="lens-content">
              <p>
                This is a small description of what this lens should be used for. Nothing to complicated and straight to the point. Nonetheless, the users are always pushing the boundaries of the product, so we have to make sure the layout doesn’t break when the description of the lens exceeds a certain amount of lines.
              </p>
              <p>
                Rhoncus nec vel mi odio. Tortor pellentesque in nibh faucibus id justo elementum venenatis pellentesque. Nibh orci vel nam fermentum a ac nibh nisl. Erat sit hendrerit accumsan eget tempus. Egestas vitae sapien aenean sit quam pulvinar. A purus elit sed velit. A vehicula diam et montes cursus lorem facilisi tellus. Nunc euismod sed vitae massa ut.
              </p>
              <p>
                Gravida ac quis mauris pharetra viverra facilisi massa mi. Justo nascetur felis nisi facilisi a elit neque ultricies. Lacus pretium iaculis turpis pulvinar iaculis. Hendrerit quis malesuada ut nullam. Tincidunt est aliquet posuere nulla praesent lectus. Eu quisque magna cras ut at ut faucibus at. Semper semper montes nulla arcu. Integer etiam risus nisl quis commodo.
              </p>
            </Box>
            <Divider />
            <Box className="files-heading" sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 0' }}>
              <Typography><FolderOpenIcon /> Documents used in this lens<b>({files.length})</b></Typography>
              <Button startIcon={<DownloadIcon />}>
                Download all documents
              </Button>
            </Box>
            <Box className="files-container">
              {files.map(file => (
                <Box className="file-pill">
                  <PictureAsPdfIcon /> <TruncatedTypography>{file}</TruncatedTypography>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </section>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="deny-dialog-title"
        aria-describedby="deny-dialog-description"
        sx={{ minWidth: '500px' }}
      >
        <DialogTitle id="deny-dialog-title">{"Reason for request denial"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="deny-dialog-description" sx={{ minWidth: '500px' }}>
            <Typography>
              Your request has been denied because:
            </Typography>
            <SelectBox options={[
                'Similar to lenses already published',
                'Inadequate sourcing/references',
                'Unclear description or purpose other'
              ]}
            />
            <Typography>
              Further explanation.
            </Typography>
            <TextField
              multiline
              rows={5}
              maxRows={10}
              variant="outlined"
              sx={{ width: '100%' }}
            />
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Cancel
          </Button>
          <Button onClick={handleCloseDialog} variant="contained" color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};

export default LensRequestReview;
