import React, { createContext, useState, useEffect } from "react";
import ApiUtils from "./ApiUtils";

const LensContext = createContext();

export const LensProvider = ({ children }) => {
  const [lenses, setLenses] = useState([]);
  const [selectedLenses, setSelectedLenses] = useState([]);
  const [accessToken, setAccessToken] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    // Load lenses from the API when the component mounts
    const loadLenses = async () => {
      if (userId && accessToken) {
        try {
          const response = await ApiUtils.getLenses(userId, accessToken);
          setLenses(response.lenses || []);
        } catch (error) {
          console.error("Error loading lenses:", error);
        }
      }
    };

    loadLenses();
  }, [userId, accessToken]);

  const addLens = async (lens) => {
    if (userId && accessToken) {
      try {
        const response = await ApiUtils.createLens(userId, lens, accessToken);
        setLenses((prevLenses) => [...(prevLenses || []), response]);
      } catch (error) {
        console.error("Error adding lens:", error);
      }
    }
  };

  const editLens = async (lensId, updatedLens) => {
    if (userId && accessToken) {
      try {
        await ApiUtils.updateLens(userId, lensId, updatedLens, accessToken);
        setLenses((prevLenses) =>
          prevLenses.map((lens) =>
            lens.lensId === lensId ? { ...lens, ...updatedLens } : lens
          )
        );
      } catch (error) {
        console.error("Error editing lens:", error);
      }
    }
  };

  const deleteLens = async (lensId) => {
    if (userId && accessToken) {
      try {
        await ApiUtils.deleteLens(userId, lensId, accessToken);
        setLenses((prevLenses) =>
          (prevLenses || []).filter((lens) => lens.lensId !== lensId)
        );
      } catch (error) {
        console.error("Error deleting lens:", error);
      }
    }
  };

  const updateSelectedLenses = (lensId, isSelected) => {
    if (isSelected) {
      if (!selectedLenses.includes(lensId)) {
        setSelectedLenses([...selectedLenses, lensId]);
      }
    } else {
      setSelectedLenses(selectedLenses.filter((lens) => lens !== lensId));
    }
  };

  return (
    <LensContext.Provider
      value={{
        lenses,
        setLenses,
        addLens,
        editLens,
        deleteLens,
        selectedLenses,
        updateSelectedLenses,
        setAccessToken,
        setUserId,
      }}
    >
      {children}
    </LensContext.Provider>
  );
};

export default LensContext;
