import { useAuth0 } from "@auth0/auth0-react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import {
  Avatar,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/ChatComponent.scss";
import ApiUtils from "../utils/ApiUtils";
import MessageActions from "../utils/MessageActions";
import {
  processFile,
  ExpandMore,
  UploadingFilesMessage,
  UploadDocumentMessage,
  generateLensArg,
} from "./ChatUtils";
import DocumentsSlider from "./DocumentsSlider";
import SummaryMessage from "./SummaryMessage";
import PromptQueryBox from "./PromptQueryBox";
import { useBrainstormValue } from "../utils/BrainstormContext";
import LensContext from "../utils/LensContext";
import { useChatContext } from "../utils/ChatContext";

function ChatComponent() {
  const { user, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const chatWindowRef = useRef(null);
  const [loadingMap, setLoadingMap] = useState({});
  const [summaryFile, setSummaryFile] = useState(null);
  const [documentsCollapsed, setDocumentsCollapsed] = useState(true);
  const [uploadFilesFinised, setUploadFilesFinished] = useState(false);
  const [showFileSummary, setShowFileSummary] = useState(false);
  const [filesHandled, setFilesHandled] = useState(false);
  const firstCallRef = useRef(true);
  const brainstormValue = useBrainstormValue();
  const { selectedLenses, lenses } = useContext(LensContext);
  const {
    sessionId,
    setSessionId,
    files,
    setFiles,
    documents,
    setDocuments,
    chatMessages,
    setChatMessages,
    isNewSession,
    setIsNewSession,
    filesInfo,
    setFilesInfo,
    accessToken,
    setAccessToken,
    loadChatSession,
  } = useChatContext();

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently, setAccessToken]);

  useEffect(() => {
    if (filesInfo.uploadingFiles && chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [filesInfo.uploadingFiles]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatMessages]);

  //UseEffect for handling uploaded files from dashboard
  useEffect(() => {
    if (
      location.state &&
      location.state.files &&
      !filesHandled &&
      firstCallRef.current
    ) {
      firstCallRef.current = false;

      setFilesHandled(true);

      const fileChangeEvent = {
        target: {
          files: location.state.files,
        },
      };

      handleFileChange(fileChangeEvent);
    }
  }, [location, filesHandled]);

  useEffect(() => {
    if (location.state && location.state.query) {
      setQuery(location.state.query);
    }
  }, [location]);
  /** Comment out fixes the session bug, but may be needed later in some fashion 
  useEffect(() => {
    const loadSession = async () => {
      if (sessionId && accessToken) {
        await loadChatSession(sessionId, accessToken);
      }
    };
    loadSession();
  }, [sessionId]);
*/
  const appendChatMessage = (sender, message, isLoading, type) => {
    // Generate message ID
    const id = `${sender}-${Date.now()}`;
    setChatMessages((prev) => [...prev, { id, sender, message, type }]);
    if (isLoading) {
      // Start loading
      setLoadingMap((prev) => ({
        ...prev,
        [id]: true,
      }));
    } else if (loadingMap[id]) {
      // Finish loading
      setLoadingMap((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
    }
    return id;
  };

  const sendDocumentSummaryRequest = async (sessionID, file, newSession) => {
    const lensArg = generateLensArg(selectedLenses, lenses);
    const accessToken = await getAccessTokenSilently();
    try {
      const responseData = await ApiUtils.sendDocumentSummary(
        sessionID,
        file,
        newSession,
        accessToken,
        lensArg,
        brainstormValue
      );
      if (responseData.error) {
        throw new Error(responseData.error);
      }
      const summary_message = responseData.answer.replace(/\n/g, "<br>");

      appendChatMessage(file.name, summary_message, false, "summary");
      return summary_message;
    } catch (error) {
      console.error(error);
    }
  };

  const sendChatQuery = async (currentSessionId) => {
    const accessToken = await getAccessTokenSilently();
    const chatHistory = chatMessages.map(msg => `${msg.sender}: ${msg.message}`).join('\n');
    const lensArg = generateLensArg(selectedLenses, lenses, chatHistory);
    if (isNewSession) {
      const blankFilename = "";
      const newSession = "True";
      try {
        const responseData = await ApiUtils.sendChatQuery(
          currentSessionId,
          query,
          blankFilename,
          newSession,
          accessToken,
          "",
          lensArg,
          brainstormValue
        );
        if (responseData.error) throw new Error(responseData.error);

        appendChatMessage(
          "AI PRIORI",
          responseData.answer.replace(/\n/g, "<br>")
        );
        setIsNewSession(false);
      } catch (error) {
        throw error;
      }
    } else {
      if (files.length === 0) {
        // If there are no files, use sendChatQuery
        try {
          const responseData = await ApiUtils.sendChatQuery(
            currentSessionId,
            query,
            "",
            "False",
            accessToken,
            "",
            lensArg,
            brainstormValue
          );
          if (responseData.error) throw new Error(responseData.error);

          appendChatMessage(
            "AI PRIORI",
            responseData.answer.replace(/\n/g, "<br>")
          );
        } catch (error) {
          throw error;
        }
      } else {
        // If there are files, use sendDocumentChatQuery
        const fileNames = files.map((file) => file.name); // Extract names from files
        try {
          const responseData = await ApiUtils.sendDocumentChatQuery(
            currentSessionId,
            query,
            fileNames,
            "False",
            accessToken,
            lensArg,
            brainstormValue
          );
          if (responseData.error) throw new Error(responseData.error);

          appendChatMessage(
            "AI PRIORI",
            responseData.answer.replace(/\n/g, "<br>")
          );
        } catch (error) {
          throw error;
        }
      }
    }
  };

  const handleQueryChange = (e) => setQuery(e.target.value);

  const handleQuerySubmission = async () => {
    const accessToken = await getAccessTokenSilently();
    if (query === "") return;
    // Check usage before sending document summary request
    const usageResponse = await ApiUtils.incrementUsage(
      user.sub,
      accessToken,
      false,
      true
    );
    if (
      usageResponse.data === "Daily query limit reached" ||
      usageResponse.data === "Both file and query limits reached for the day"
    ) {
      alert(usageResponse.data);
      return;
    }
    setLoading(true);
    let currentSessionId = sessionId;
    try {
      if (!query && (!files || files.length === 0)) {
        throw new Error("Please input a query or upload a file.");
      } else if (isNewSession) {
        const accessToken = await getAccessTokenSilently();
        try {
          const responseData = await ApiUtils.initiateNewSession(
            user,
            query,
            accessToken
          );
          setSessionId(responseData.sessionId);
          currentSessionId = responseData.sessionId;
          setIsNewSession(false);
          if (responseData.error) throw new Error(responseData.error);
        } catch (error) {
          throw error;
        }
      }
      const id = appendChatMessage("User", query, true);
      setQuery("");
      await sendChatQuery(currentSessionId);
      setLoadingMap((prev) => {
        const updated = { ...prev };
        delete updated[id];
        return updated;
      });
    } catch (error) {
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handleFileChange = (e) => {
    processFile(e, {
      files,
      setFiles,
      documents,
      setDocuments,
      sessionId,
      setSessionId,
      setLoading,
      filesInfo,
      setFilesInfo,
      isNewSession,
      setIsNewSession,
      setUploadFilesFinished,
      user,
      getAccessTokenSilently,
      alert,
      sendDocumentSummaryRequest,
    });
  };
  const handleCancelUploadFiles = () => {};
  const handleFilesDialogClose = () => {
    setUploadFilesFinished(false);
  };
  const handleFileSummaryDialogClose = () => {
    setShowFileSummary(false);
  };
  const toggleDocumentsCollapsed = () => {
    setDocumentsCollapsed((value) => !value);
  };

  return (
    <>
      <script
        src="https://kit.fontawesome.com/330be5aa1f.js"
        crossOrigin="anonymous"
      ></script>
      <Box className="app-section files">
        <div className="section-title">
          <h3>
            <FolderOpenOutlinedIcon sx={{ color: "#47246B" }} /> Documents{" "}
            <span>({documents.length})</span>
          </h3>
          <div className="title-actions">
            {documents.length > 0 && (
              <Button
                startIcon={<FileUploadIcon />}
                component="label"
                disabled={loading}
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                Upload More Documents
                <input
                  type="file"
                  id="pdfFile"
                  name="pdfFile"
                  accept=".pdf"
                  onChange={handleFileChange}
                  multiple
                />
              </Button>
            )}
            <div className="collapse-toggle">
              <IconButton onClick={toggleDocumentsCollapsed}>
                <ExpandMore
                  expand={documentsCollapsed}
                  aria-expanded={documentsCollapsed}
                  aria-label="show more"
                  sx={{
                    minWidth: 0,
                    justifyContent: "center",
                    color: "#47246B",
                  }}
                />
              </IconButton>
            </div>
          </div>
        </div>
        <Collapse in={documentsCollapsed} timeout="auto" unmountOnExit>
          <DocumentsSlider
            documents={documents}
            onShowFileSummary={(file) => {
              setShowFileSummary(true);
              setSummaryFile(file);
            }}
          />
        </Collapse>
      </Box>
      <Box className="app-section chat-wrapper">
        {chatMessages.length === 0 && !filesInfo.uploadingFiles ? (
          <>
            <UploadDocumentMessage
              handleFileChange={handleFileChange}
              loading={loading}
            />
            <PromptQueryBox
              query={query}
              handleQueryChange={handleQueryChange}
              handleQuerySubmission={handleQuerySubmission}
              handleFileChange={handleFileChange}
              loading={loading}
            />
          </>
        ) : (
          <>
            <div className="chat-window" ref={chatWindowRef}>
              {chatMessages.map((msg, index) => {
                if (msg.type === "summary")
                  return <SummaryMessage key={index} {...msg} />;

                return (
                  <div
                    key={`${msg.sender}-${index}-${new Date().toISOString()}`}
                    className={`chat-message ${msg.sender.toLowerCase()} ${
                      msg.type || ""
                    }`}
                  >
                    <div className="message-wrapper">
                      <Avatar
                        src={
                          msg.sender.includes("AI PRIORI") ||
                          msg.sender.includes("Attention")
                            ? "../../img/ai-priori-avatar.svg"
                            : user.picture
                        }
                        alt=""
                      />
                      <div className="message-content">
                        {typeof msg.message === "string"
                          ? msg.message.split("<br>").map((line, lineIndex) => (
                              <span key={lineIndex}>
                                {line}
                                {lineIndex <
                                  msg.message.split("<br>").length - 1 && (
                                  <br />
                                )}
                              </span>
                            ))
                          : msg.message}
                        {/* Render ProgressBar if isLoading is true */}
                        {loadingMap[msg.id] && (
                          <CircularProgress
                            sx={{
                              width: "24px",
                              display: "block",
                              marginTop: 2,
                            }}
                            size="small"
                          />
                        )}
                        {msg.sender.includes("AI PRIORI") && (
                          <MessageActions
                            systemResponseText={msg.message
                              .split("<br>")
                              .join("\n")}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {filesInfo.uploadingFiles && (
                <UploadingFilesMessage
                  onCancel={handleCancelUploadFiles}
                  totalFiles={filesInfo.totalFiles}
                  completedFiles={filesInfo.uploadedFiles}
                />
              )}
            </div>
            <PromptQueryBox
              query={query}
              handleQueryChange={handleQueryChange}
              handleQuerySubmission={handleQuerySubmission}
              handleFileChange={handleFileChange}
              loading={loading}
            />
          </>
        )}
      </Box>
      <Dialog
        open={uploadFilesFinised}
        onClose={handleFilesDialogClose}
        aria-labelledby="finished-files-dialog-title"
        aria-describedby="finished-files-dialog-description"
      >
        <DialogTitle id="finished-files-dialog-title">
          {"Your files have been uploaded."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="finished-files-dialog-description">
            Now you can ask as many questions as you want and AI PRIORI® will
            use these documents to give you the best and most relevant answers.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            flexFlow: {
              xs: "column",
              sm: "initial",
              md: "initial",
              xl: "initial",
            },
          }}
        >
          <Button onClick={handleFilesDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={summaryFile && showFileSummary}
        onClose={handleFileSummaryDialogClose}
      >
        <SummaryMessage
          closeButton
          onClose={handleFileSummaryDialogClose}
          sender={summaryFile ? summaryFile.name : ""}
          message={summaryFile ? summaryFile.summary_message : ""}
        />
      </Dialog>
    </>
  );
}

export default ChatComponent;
