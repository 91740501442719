import { useAuth0 } from "@auth0/auth0-react";
import {
  Button,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Check } from "@mui/icons-material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/ChatComponent.scss";
import "../../styles/CreateLens.scss";
import LensContext from "../utils/LensContext";
import DefinitionLens from "./LensUtils/DefinitionLens";
import OrgGoalsStrategiesLens from "./LensUtils/OrgGoalsStrategies";
import PriorityAudienceLens from "./LensUtils/PriorityAudience";
import PersonaLens from "./LensUtils/Persona";
import TaskOrientationLens from "./LensUtils/TaskOrientation";
import ContextStoriesLens from "./LensUtils/ContextStories";

const getSteps = (lensType) => {
  if (lensType === "Definition") {
    return ["Type of Lens", "Lens Definition", "Examples"];
  } else if (lensType === "Organization Goals and Strategies") {
    return ["Type of Lens", "Org Strategies and Goals"];
  } else if (lensType === "Priority Audience") {
    return ["Type of Lens", "Audience Description", "Audience Characteristics"];
  } else if (lensType === "Persona") {
    return ["Type of Lens", "Persona Details", "Persona Characteristics"];
  } else if (lensType === "Task Orientation") {
    return ["Type of Lens", "Task Details", "Task Characteristics"];
  } else if (lensType === "Context Stories") {
    return ["Type of Lens", "Context Stories"];
  }
  return [];
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 30px)",
    right: "calc(50% + 30px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#47246B",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#47246B",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 1,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#47246B",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#47246B",
    zIndex: 1,
    fontSize: 15,
  },
  "& .QontoStepIcon-circle": {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

function EditLens() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [activeStep, setActiveStep] = React.useState(0);
  const [finished, setFinished] = React.useState(false);
  const navigate = useNavigate();
  const { lensId } = useParams();
  const { lenses, editLens, setAccessToken, setUserId } =
    useContext(LensContext);
  const [wordOrPhrase, setWordOrPhrase] = useState("");
  const [definition, setDefinition] = useState("");
  const [synonyms, setSynonyms] = useState([]);
  const [examples, setExamples] = useState([]);
  const [lensType, setLensType] = useState("");
  const [goals, setGoals] = useState([]);
  const [values, setValues] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [lensName, setLensName] = useState("");
  const [audienceName, setAudienceName] = useState("");
  const [audienceDescription, setAudienceDescription] = useState("");
  const [audienceCharacteristics, setAudienceCharacteristics] = useState([]);
  const [audienceLikes, setAudienceLikes] = useState([]);
  const [audienceDislikes, setAudienceDislikes] = useState([]);
  const [audienceInteractions, setAudienceInteractions] = useState([]);
  const [personaName, setPersonaName] = useState("");
  const [personaDescription, setPersonaDescription] = useState("");
  const [personaBackground, setPersonaBackground] = useState("");
  const [personaCharacteristics, setPersonaCharacteristics] = useState("");
  const [personaValues, setPersonaValues] = useState("");
  const [communicationStyle, setCommunicationStyle] = useState("");
  const [scenarioContext, setScenarioContext] = useState("");
  const [personaDialogue, setPersonaDialogue] = useState("");
  const [taskDefinition, setTaskDefinition] = useState("");
  const [desiredOutcome, setDesiredOutcome] = useState("");
  const [challenges, setChallenges] = useState("");
  const [peopleInvolved, setPeopleInvolved] = useState("");
  const [perspective, setPerspective] = useState("");
  const [contextStory1, setContextStory1] = useState("");
  const [contextStory2, setContextStory2] = useState("");
  const [contextStory3, setContextStory3] = useState("");

  const steps = getSteps(lensType);

  useEffect(() => {
    const getAccessToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        setAccessToken(token);
        setUserId(user.sub);
      } catch (error) {
        console.error("Error getting access token:", error);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently, setAccessToken, setUserId, user]);

  useEffect(() => {
    const lens = lenses.find((l) => l.lensId === lensId);
    if (lens && !lensType) {
      setLensType(lens.lens_type);
      switch (lens.lens_type) {
        case "Definition":
          setWordOrPhrase(lens.word_or_phrase || "");
          setDefinition(lens.definition || "");
          setSynonyms(lens.synonyms || []);
          setExamples(lens.examples || []);
          break;
        case "Organization Goals and Strategies":
          setLensName(lens.lensName || "");
          setGoals(lens.goals || []);
          setValues(lens.values || []);
          setStrategies(lens.strategies || []);
          break;
        case "Priority Audience":
          setAudienceName(lens.audienceName || "");
          setAudienceDescription(lens.audienceDescription || "");
          setAudienceCharacteristics(lens.audienceCharacteristics || []);
          setAudienceLikes(lens.audienceLikes || []);
          setAudienceDislikes(lens.audienceDislikes || []);
          setAudienceInteractions(lens.audienceInteractions || []);
          break;
        case "Persona":
          setPersonaName(lens.personaName || "");
          setPersonaDescription(lens.personaDescription || "");
          setPersonaBackground(lens.personaBackground || "");
          setPersonaCharacteristics(lens.personaCharacteristics || "");
          setPersonaValues(lens.personaValues || "");
          setCommunicationStyle(lens.communicationStyle || "");
          setScenarioContext(lens.scenarioContext || "");
          setPersonaDialogue(lens.personaDialogue || "");
          break;
        case "Task Orientation":
          setLensName(lens.lensName || "");
          setTaskDefinition(lens.taskDefinition || "");
          setDesiredOutcome(lens.desiredOutcome || "");
          setChallenges(lens.challenges || "");
          setPeopleInvolved(lens.peopleInvolved || "");
          break;
        case "Context Stories":
          setLensName(lens.lensName || "");
          setPerspective(lens.perspective || "");
          setContextStory1(lens.contextStory1 || "");
          setContextStory2(lens.contextStory2 || "");
          setContextStory3(lens.contextStory3 || "");
          break;
        default:
          break;
      }
    } else if (!lens) {
      navigate("/chat");
    }
  }, [lensId, lenses, navigate]);

  const nextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
    window.scrollTo(0, 0);
  };

  const prevStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
    window.scrollTo(0, 0);
  };

  const onFinish = () => {
    let updatedLens;
    switch (lensType) {
      case "Definition":
        updatedLens = {
          lens_type: "Definition",
          word_or_phrase: wordOrPhrase,
          definition,
          synonyms,
          examples,
        };
        break;
      case "Organization Goals and Strategies":
        updatedLens = {
          lens_type: "Organization Goals and Strategies",
          lensName,
          goals,
          values,
          strategies,
        };
        break;
      case "Priority Audience":
        updatedLens = {
          lens_type: "Priority Audience",
          audienceName,
          audienceDescription,
          audienceCharacteristics,
          audienceLikes,
          audienceDislikes,
          audienceInteractions,
        };
        break;
      case "Persona":
        updatedLens = {
          lens_type: "Persona",
          personaName,
          personaDescription,
          personaBackground,
          personaCharacteristics,
          personaValues,
          communicationStyle,
          scenarioContext,
          personaDialogue,
        };
        break;
      case "Task Orientation":
        updatedLens = {
          lens_type: "Task Orientation",
          lensName,
          taskDefinition,
          desiredOutcome,
          challenges,
          peopleInvolved,
        };
        break;
      case "Context Stories":
        updatedLens = {
          lens_type: "Context Stories",
          lensName,
          perspective,
          contextStory1,
          contextStory2,
          contextStory3,
        };
        break;
      default:
        break;
    }
    editLens(lensId, updatedLens);
    setActiveStep((prevStep) => prevStep + 1);
    setFinished(true);
    window.scrollTo(0, 0);
  };

  const handleClose = () => {
    navigate("/chat");
  };

  return (
    <>
      <Box className="app-section">
        <Box className="ai-card">
          <Box className="heading">
            <h3>
              <b>Edit Lens: </b>
              {lensType}
            </h3>
          </Box>
          <Box className="card-section-content">
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={QontoStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider />
            <Box className={`lens-creation-content step-${activeStep + 2}`}>
              {(activeStep === 0 || activeStep === 1) &&
                lensType === "Definition" && (
                  <DefinitionLens
                    wordOrPhrase={wordOrPhrase}
                    setWordOrPhrase={setWordOrPhrase}
                    definition={definition}
                    setDefinition={setDefinition}
                    synonyms={synonyms}
                    setSynonyms={setSynonyms}
                    examples={examples}
                    setExamples={setExamples}
                    activeStep={activeStep + 1}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    isEditing={true}
                  />
                )}
              {(activeStep === 0 || activeStep === 1) &&
                lensType === "Persona" && (
                  <PersonaLens
                    personaName={personaName}
                    setPersonaName={setPersonaName}
                    personaDescription={personaDescription}
                    setPersonaDescription={setPersonaDescription}
                    personaBackground={personaBackground}
                    setPersonaBackground={setPersonaBackground}
                    personaCharacteristics={personaCharacteristics}
                    setPersonaCharacteristics={setPersonaCharacteristics}
                    personaValues={personaValues}
                    setPersonaValues={setPersonaValues}
                    communicationStyle={communicationStyle}
                    setCommunicationStyle={setCommunicationStyle}
                    scenarioContext={scenarioContext}
                    setScenarioContext={setScenarioContext}
                    personaDialogue={personaDialogue}
                    setPersonaDialogue={setPersonaDialogue}
                    activeStep={activeStep + 1}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    isEditing={true}
                  />
                )}
              {activeStep === 0 &&
                lensType === "Organization Goals and Strategies" && (
                  <OrgGoalsStrategiesLens
                    lensName={lensName}
                    setLensName={setLensName}
                    goals={goals}
                    setGoals={setGoals}
                    values={values}
                    setValues={setValues}
                    strategies={strategies}
                    setStrategies={setStrategies}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    isEditing={true}
                  />
                )}
              {(activeStep === 0 || activeStep === 1) &&
                lensType === "Priority Audience" && (
                  <PriorityAudienceLens
                    audienceName={audienceName}
                    setAudienceName={setAudienceName}
                    audienceDescription={audienceDescription}
                    setAudienceDescription={setAudienceDescription}
                    audienceCharacteristics={audienceCharacteristics}
                    setAudienceCharacteristics={setAudienceCharacteristics}
                    audienceLikes={audienceLikes}
                    setAudienceLikes={setAudienceLikes}
                    audienceDislikes={audienceDislikes}
                    setAudienceDislikes={setAudienceDislikes}
                    audienceInteractions={audienceInteractions}
                    setAudienceInteractions={setAudienceInteractions}
                    activeStep={activeStep + 1}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    isEditing={true}
                  />
                )}
              {(activeStep === 0 || activeStep === 1) &&
                lensType === "Task Orientation" && (
                  <TaskOrientationLens
                    lensName={lensName}
                    setLensName={setLensName}
                    taskDefinition={taskDefinition}
                    setTaskDefinition={setTaskDefinition}
                    desiredOutcome={desiredOutcome}
                    setDesiredOutcome={setDesiredOutcome}
                    challenges={challenges}
                    setChallenges={setChallenges}
                    peopleInvolved={peopleInvolved}
                    setPeopleInvolved={setPeopleInvolved}
                    activeStep={activeStep + 1}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    onFinish={onFinish}
                    isEditing={true}
                  />
                )}
              {activeStep === 0 && lensType === "Context Stories" && (
                <ContextStoriesLens
                  lensName={lensName}
                  setLensName={setLensName}
                  perspective={perspective}
                  setPerspective={setPerspective}
                  contextStory1={contextStory1}
                  setContextStory1={setContextStory1}
                  contextStory2={contextStory2}
                  setContextStory2={setContextStory2}
                  contextStory3={contextStory3}
                  setContextStory3={setContextStory3}
                  activeStep={activeStep + 1}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  onFinish={onFinish}
                  isEditing={true}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={finished}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your lens has been updated successfully."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can now access and use your updated lens through the left column
            Menu under the Lenses Category.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            flexFlow: {
              xs: "column",
              sm: "initial",
              md: "initial",
              xl: "initial",
            },
          }}
        >
          <Button onClick={handleClose}>Return to lenses</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditLens;
