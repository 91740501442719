import React from "react";
import { FormControl, InputLabel, TextField, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OrgGoalsStrategiesLens = ({
  lensName,
  setLensName,
  goals,
  setGoals,
  values,
  setValues,
  strategies,
  setStrategies,
  prevStep,
  onFinish,
  isEditing = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <p>
        Generate responses that consider your organization’s goals and
        strategies. When applied, AI PRIORI will consider these nuances and
        integrate them into the response.
      </p>
      <div className="orgGoalsStrategies">
        <FormControl className="top-labeled">
          <InputLabel shrink htmlFor="name-input">
            Lens Name
            {/*<span className="required">*</span>*/}
          </InputLabel>
          <TextField
            required
            placeholder="Write here"
            id="name-input"
            value={lensName}
            onChange={(e) => setLensName(e.target.value)}
          />
        </FormControl>
        <FormControl className="top-labeled">
          <InputLabel shrink htmlFor="goals-input">
            Goal(s): What does your organization seek to achieve/accomplish?
            {/*<span className="required">*</span>*/}
          </InputLabel>
          <TextField
            required
            placeholder="Write here"
            id="goals-input"
            value={goals}
            onChange={(e) => setGoals(e.target.value)}
            multiline
            rows={4}
          />
        </FormControl>
        <FormControl className="top-labeled">
          <InputLabel shrink htmlFor="values-input">
            Value(s): Core principles and/or beliefs held by the organization
            {/*<span className="required">*</span>*/}
          </InputLabel>
          <TextField
            required
            placeholder="Write here"
            id="values-input"
            value={values}
            onChange={(e) => setValues(e.target.value)}
            multiline
            rows={4}
          />
        </FormControl>
        <FormControl className="top-labeled">
          <InputLabel shrink htmlFor="strategies-input">
            Strategies: How does your organization plan to achieve its goals?
            What approaches will it use?
            {/*<span className="required">*</span>*/}
          </InputLabel>
          <TextField
            multiline
            required
            rows={4}
            placeholder="Write here"
            id="strategies-input"
            value={strategies}
            onChange={(e) => setStrategies(e.target.value)}
          />
        </FormControl>
      </div>
      <div className="actions">
        {isEditing ? (
          <>
            <Button onClick={() => navigate("/chat")}>Cancel</Button>
            <Button variant="contained" onClick={onFinish}>
              Update lens
            </Button>
          </>
        ) : (
          <>
            <Button onClick={prevStep}>Back</Button>
            <Button variant="contained" onClick={onFinish}>
              Create lens
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default OrgGoalsStrategiesLens;
