import React from "react";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import LanguageIcon from '@mui/icons-material/Language';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { Button, Card, CardActions, CardContent, Checkbox, Divider, FormControlLabel, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MoreOptionsMenu } from "../Menus/MoreOptionsMenu";

const LensCard = ({ tag, info, addToLibrary, admin, onSelectAction, selected, pseudoId, onDeny, onApprove, onDeleteLens, onEditLens }) => {
    const navigate = useNavigate();

    const {
        title,
        excerpt,
        authors,
        published,
        files,
        id,
        type,
        status,
        version,
        favorited,
        likes
    } = info;

    const handleAddToLibrary = () => {
        addToLibrary(info)
    }
    const handleChange = (event) => {
        onSelectAction(pseudoId, event.target.checked ? 'select' : 'deselect');
    }

    const [fav, setFav ] = React.useState(favorited)

    const  formatDateToReadable = (dateString) => {
        const date = new Date(dateString);
    
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
    
        const day = date.getDate();
        const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
    
        return formattedDate.replace(/(\d+)(,)/, `$1${daySuffix(day)},`);
    }
    return (
        <Card className="lens-card">
            <CardContent>
                <div className="upperGroup">
                    <div className="card-header">
                        {!admin && (
                            <span className={`tag ${type}`}>
                                {type == 'personal' && <PersonOutlineIcon />}
                                {type == 'featured' && <WhatshotIcon />}
                                {type == 'community' && <LanguageIcon />} {type}
                            </span>
                        )}
                        {admin && (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selected}
                                        onChange={handleChange}
                                    />
                                }
                                sx={{ marginRight: 0 }}
                            />
                        )}
                        <MoreOptionsMenu />
                    </div>
                    <Divider />
                    <div className="card-body" onClick={() => navigate(admin ? `/lenses/request-review/${id}` : `/lenses/view/${id}`)}>
                        <div className="tag-version">
                            <span className={`tag ${type} show-list`}>
                                {type == 'personal' && <PersonOutlineIcon />}
                                {type == 'featured' && <WhatshotIcon />}
                                {type == 'community' && <LanguageIcon />} {type}
                            </span>
                            <span className="version">V {version}</span>
                        </div>
                        <Typography component="h7">
                            {title}
                        </Typography>
                        <Typography component="p">
                            {excerpt}
                        </Typography>
                    </div>
                </div>
                <div className="lowerGroup" onClick={() => navigate(admin ? `/lenses/request-review/${id}` : `/lenses/view/${id}`)}>
                    <Divider />
                    <div className="card-info">
                        <Typography>
                            <PeopleOutlineIcon /> {authors}
                        </Typography>
                        <Typography>
                            <CalendarTodayIcon /> published {formatDateToReadable(published)}
                        </Typography>
                        <Typography>
                            <FolderOpenIcon /> {files.length} file{files.length > 1 ? 's' : ''}
                        </Typography>
                    </div>
                    <Divider />
                </div>
            </CardContent>
            <CardActions>
                {!admin && (<>
                    <div className="actions">
                        <Button onClick={() => handleAddToLibrary()}>
                            <AddCircleIcon />
                        </Button>
                        <Button onClick={() => setFav(!fav)}>
                            {fav ? <StarIcon /> : <StarBorderIcon/>}
                        </Button>
                        <Button>
                            <ShareIcon />
                        </Button>
                        <MoreOptionsMenu className="show-list" />
                    </div>
                    <Button startIcon={<RemoveRedEyeIcon />}>
                        Try Lens
                    </Button>
                </>)}
                {admin && (
                    <>
                        <Button startIcon={<CloseIcon />} color="error" onClick={onDeny}>Deny</Button>
                        <Button startIcon={<CheckIcon />} onClick={onApprove}>Approve</Button>
                    </>
                )}
            </CardActions>
        </Card>
    );
};

export default LensCard;
