
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import React from "react";
export const MoreOptionsMenu = ({
    onDeleteLens,
    onEditLens,
    className
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMoreOptions = Boolean(anchorEl);
    const handleClickeMoreOptions = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleCloseMoreOptions = () => {
      setAnchorEl(null);
    };
    const onDelete = () => {
        if(onDeleteLens)
            onDeleteLens()
      handleCloseMoreOptions()
    }
    const onEdit = () => {
        if(onEditLens)
            onEditLens()

      handleCloseMoreOptions()
    }
  
    return (
        <>
            <Button onClick={handleClickeMoreOptions} className={`${className}`}>
                <MoreHorizIcon />
            </Button>
            <Menu
                id="more-options-menu-view"
                anchorEl={anchorEl}
                open={openMoreOptions}
                onClose={handleCloseMoreOptions}
                MenuListProps={{
                    'aria-labelledby': 'more-options-menu-view',
                }}
            >
                <MenuItem onClick={onDelete} >
                    <ListItemIcon><EditIcon /></ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={onEdit} >
                    <ListItemIcon><DeleteIcon color="error" /></ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};