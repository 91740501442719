import AddIcon from '@mui/icons-material/Add';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import LanguageIcon from '@mui/icons-material/Language';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import { useParams } from 'react-router-dom';

import {
  Box, Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router-dom";
import LensesSlider from '../components/DocChat/LensUtils/LensesSlider';
import RemixIcon from "../components/Icons/RemixIcon";
import AppLayout from "../layouts/AppLayout";
import "../styles/LensView.scss";
import { MoreOptionsMenu } from '../components/Menus/MoreOptionsMenu';
import lensesData from "../data/lenses_feed_example.json";

const lensTest = {
  title: 'This is the title of the Lens, which can vary in length but the design should adjust to it doesn’t matter how long it is. ',
  excerpt: 'This is a small description of what this lens should be used for. Nothing to complicated and straight to the point. Nonetheless, the users are always pushing the boundaries of the product, so we have to make sure the layout doesn’t break when the description of the lens exceeds a certain amount of lines. You can always truncate the text beyond a point that you think is correct.',
  authors: 'Emily Yu, Bryan Speelman + 4',
  published: '2024-04-13',
  files: [
    'Healthy Housing.doc',
    'FairHousing2023.pdf',
    'CollaborationHousin...',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
  ],
  id: '1234',
  version: '2.2',
  type: "personal",
  status: "publish",
}

const lenses = [
  lensTest,
  lensTest,
  lensTest,
  lensTest,
  lensTest
]

const LensView = () => {
  const navigate = useNavigate();
  const { lensId } = useParams();

  const lens = lensesData.find((lens) => lens.id === lensId);
  const tag = "personal";

  const {
    title,
    excerpt,
    authors,
    published,
    files,
    id,
    type,
    version,
    favorited
  } = lens;
  const TruncatedTypography = styled(Typography)({
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "0.9rem",
    lineHeight: "1.2",
    textOverflow: "ellipsis",
    maxWidth: "113px",
  });
  const addToLibrary = () => {

  }
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (lens) => {
    setOpenDialog(true);
  };

  const [fav, setFav] = React.useState(favorited)
  const formatDateToReadable = (dateString) => {
    const date = new Date(dateString);

    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const day = date.getDate();
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return formattedDate.replace(/(\d+)(,)/, `$1${daySuffix(day)},`);
  }

  return (
    <AppLayout contentWithTitle>
      <section className="lens-view">
        <Box sx={{ bgcolor: "background.paper" }} className="lenses-container">
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
            <Button startIcon={<ChevronLeftIcon />} onClick={() => navigate('/lenses')}>
              Go Back
            </Button>
            <Box sx={{ display: 'flex', gap: '15px' }}>
              <Button startIcon={<RemoveRedEyeIcon />}>
                Try Lens
              </Button>
              <Button startIcon={<RemixIcon />}>
                Remix
              </Button>
              <Button variant="contained" startIcon={<AddIcon />}>
                Add to my lens library
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box className="lens-container">
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
              <span className={`tag ${type}`}>
                {type == 'personal' && <PersonOutlineIcon />}
                {type == 'featured' && <WhatshotIcon />}
                {type == 'community' && <LanguageIcon />} {type}
              </span>
              <Box sx={{ display: 'block' }}>
                <Button onClick={() => setFav(!fav)}>
                  {fav ? <StarIcon /> : <StarBorderIcon />}
                </Button>
                <Button>
                  <ShareIcon />
                </Button>
                <MoreOptionsMenu />
              </Box>
            </Box>
            <Typography component="h1">
              {title}
            </Typography>
            <Box className="lens-info">
              <Typography>
                <PeopleOutlineIcon /> {authors}
              </Typography>
              <Typography>
                <CalendarTodayIcon /> published {formatDateToReadable(published)}
              </Typography>
              <Typography>
                <FolderOpenIcon /> {files.length} file{files.length > 1 ? 's' : ''}
              </Typography>
            </Box>
            <Box className="lens-content">
              <p>
                This is a small description of what this lens should be used for. Nothing to complicated and straight to the point. Nonetheless, the users are always pushing the boundaries of the product, so we have to make sure the layout doesn’t break when the description of the lens exceeds a certain amount of lines.
              </p>
              <p>
                Rhoncus nec vel mi odio. Tortor pellentesque in nibh faucibus id justo elementum venenatis pellentesque. Nibh orci vel nam fermentum a ac nibh nisl. Erat sit hendrerit accumsan eget tempus. Egestas vitae sapien aenean sit quam pulvinar. A purus elit sed velit. A vehicula diam et montes cursus lorem facilisi tellus. Nunc euismod sed vitae massa ut.
              </p>
              <p>
                Gravida ac quis mauris pharetra viverra facilisi massa mi. Justo nascetur felis nisi facilisi a elit neque ultricies. Lacus pretium iaculis turpis pulvinar iaculis. Hendrerit quis malesuada ut nullam. Tincidunt est aliquet posuere nulla praesent lectus. Eu quisque magna cras ut at ut faucibus at. Semper semper montes nulla arcu. Integer etiam risus nisl quis commodo.
              </p>
            </Box>
            <Divider />
            <Box className="files-heading" sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px 0' }}>
              <Typography><FolderOpenIcon /> Documents used in this lens<b>({files.length})</b></Typography>
              <Button startIcon={<DownloadIcon />}>
                Download all documents
              </Button>
            </Box>
            <Box className="files-container">
              {files.map(file => (
                <Box className="file-pill">
                  <PictureAsPdfIcon /> <TruncatedTypography>{file}</TruncatedTypography>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
            <Typography>Similar Lenses</Typography>
            <Button>
              View more similar lenses
            </Button>
          </Box>
          <Box sx={{ padding: '24px', paddingTop: '0' }}>
            <LensesSlider lenses={lenses} onAddToLibrary={handleOpenDialog} />
          </Box>
        </Box>
      </section>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"The lens has been added to your library and is ready to use."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                />
              }
              label="Don’t show this message again."
              sx={{ marginRight: 0 }}
            />
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};

export default LensView;
