import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import IconButton from "@mui/material/IconButton";
import React from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import LensCard from "../../Lenses/LensCard";
export default function LensesSlider({ lenses, onAddToLibrary }) {
    const handleOpenDialog = (lens) => {
        if (onAddToLibrary) onAddToLibrary(lens);
    };
    return (
        <div className="all-documents">
            {lenses.length === 0 ? (
                <div className="empty-documents">
                    <div className="disabled-arrow">
                        <ArrowBackOutlinedIcon />
                    </div>
                    <div className="message">No documents yet.</div>
                    <div className="disabled-arrow">
                        <ArrowForwardOutlinedIcon />
                    </div>
                </div>
            ) : (
                <>
                    <div className="swiper-container">
                        <IconButton className="arrow-left">
                            <ArrowBackIosIcon />
                        </IconButton>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={5}
                            navigation={{ nextEl: ".arrow-right", prevEl: ".arrow-left" }}
                            modules={[Navigation]}
                            breakpoints={{
                                768: {
                                    slidesPerView: 1,
                                },
                                992: {
                                    slidesPerView: 1,
                                },
                                1024: {
                                    slidesPerView: 2,
                                },
                                1200: {
                                    slidesPerView: 3,
                                },
                            }}
                        >
                            {lenses.map((doc, i) => (
                                <SwiperSlide key={`slide-document-${i}`}>
                                    <LensCard tag="personal" info={doc} addToLibrary={handleOpenDialog} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <IconButton className="arrow-right">
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </div>
                </>
            )}
        </div>
    );
}
