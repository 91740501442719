import { useEffect } from 'react';
import Tutorials from '../components/Tutorials';
import LandingLayout from '../layouts/LandingLayout';

const Dashboard = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <LandingLayout className="page-tutorials">
            <Tutorials />
        </LandingLayout>
    )
}

export default Dashboard;