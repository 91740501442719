import { useAuth0 } from "@auth0/auth0-react";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Menu from "@mui/material/Menu";
import Paper from "@mui/material/Paper";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "../styles/header.scss";
import ChatSettings from "./ChatSettings";
import ProfileMenu from "./ProfileMenu";
import { ArrowDropDown, ExpandMoreOutlined, Margin } from "@mui/icons-material";
import { useChatContext } from "./utils/ChatContext";
import NavButtonChildren from "./Buttons/NavButtonChildren";

const pages = [
  { label: "Tutorial", path: "/tutorial" },
  { label: "Help", path: "/help" },
  //{ label: "FAQs", path: "/faqs" },
];
const authPages = [
  { label: "Current Chat", path: "/chat" },
  /** 
  {
    label: "Lenses",
    children: [
      { label: "Create New Lens", path: "/chat/lens/create" },
      { label: "Create New Lens From Library", path: "/lenses" },
      { label: "Drafts", path: "/lenses/draft" },
      { label: "My lenses", path: "/lenses/personal" },
      { label: "On review", path: "/lenses/review" },
    ]
  },
  { label: "Lens Library", path: "/lenses" },
   */
  { label: "Create New Lens", path: "/chat/lens/create" },
];

function ResponsiveAppBar({ searchBar }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { startNewChat } = useChatContext();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { isAuthenticated, loginWithRedirect, logout, error, user } =
    useAuth0();

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };
  const [settingsDrawer, setSettingsDrawer] = React.useState(false);
  const [menuDrawer, setMenuDrawer] = React.useState(false);

  const toggleSettingsDrawer = () => {
    setSettingsDrawer(!settingsDrawer);
  };
  const toggleMenuDrawer = () => {
    setMenuDrawer(!menuDrawer);
  };

  const isCurrent = (path) => {
    return path === location.pathname;
  };

  return (
    <>
      <AppBar position="fixed" style={{ background: "#FFF" }}>
        <Container maxWidth="1860px">
          <Toolbar disableGutters>
            {(isAuthenticated || error) && (
              <Box sx={{ flexGrow: 0, display: { md: "flex", lg: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="toggle chat settings menu"
                  aria-controls="menu-chat-settings"
                  aria-haspopup="true"
                  onClick={toggleSettingsDrawer}
                >
                  <TuneIcon />
                </IconButton>
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex" },
                flex: { lg: "0 0 229px" },
              }}
            >
              <img
                src="../../img/ai-priori-logo.svg"
                alt="AI Priori"
                className="header-logo"
                onClick={() => navigate("/")}
              />
              <Box
                component="span"
                sx={{
                  mt: 1,
                  backgroundColor: "primary.main",
                  color: "white",
                  p: "0px 8px",
                  borderRadius: "4px",
                  fontSize: "0.75rem",
                  marginLeft: "74px",
                  marginTop: "35px",
                  position: "absolute",
                  height: "17px",
                }}
              >
                BETA
              </Box>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                display: {
                  xs: "none",
                  md: "none",
                  lg: "flex",
                },
                mr: "32px",
                alignItems: "center",
              }}
              justifyContent={
                isAuthenticated || error ? "space-between" : "end"
              }
            >
              {(isAuthenticated || error) && (
                <Button
                  variant="contained"
                  startIcon={<AddCommentIcon />}
                  onClick={() => {
                    startNewChat();
                    navigate("/chat");
                  }}
                >
                  New Chat
                </Button>
              )}
              <div style={{ display: "flex" }}>
                {(isAuthenticated || error) &&
                  authPages.map((page) => {
                    if (page.children) return <NavButtonChildren page={page} />;

                    return (
                      <Button
                        key={page.label}
                        onClick={() => navigate(page.path)}
                        sx={{
                          display: "block",
                          textTransform: "none",
                          color: isCurrent(page.path) ? "#47246B" : "#00000099",
                        }}
                      >
                        {page.label}
                      </Button>
                    );
                  })}
                {pages.map((page) => (
                  <Button
                    key={page.label}
                    onClick={() => navigate(page.path)}
                    sx={{
                      display: "block",
                      textTransform: "none",
                      color: isCurrent(page.path) ? "#47246B" : "#00000099",
                    }}
                  >
                    {page.label}
                  </Button>
                ))}

                {/** Hide header search bar
                !!searchBar && (isAuthenticated || error) && <Paper
                                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 230 }}
                                    className="search-bar"
                                >
                                    <form action="/search">
                                        <IconButton sx={{ p: '10px' }} aria-label="search" type="submit">
                                            <SearchIcon />
                                        </IconButton>
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Search"
                                            inputProps={{ 'aria-label': 'search' }}
                                            name="q"
                                            autoComplete='off'
                                            defaultValue={searchParams.get('q')}
                                        />
                                    </form>
                                </Paper>*/}
                {!isAuthenticated && !error && (
                  <Button variant="contained" onClick={handleLogin}>
                    Log in
                  </Button>
                )}
              </div>
            </Box>

            {!isAuthenticated && !error && (
              <Button
                className="appbar-button"
                variant="contained"
                sx={{
                  display: {
                    xs: "flex",
                    md: "none",
                  },
                }}
                onClick={handleLogin}
              >
                Log in
              </Button>
            )}
            {(isAuthenticated || error) && (
              <Button
                className="appbar-button btn-icon"
                variant="contained"
                startIcon={<AddCommentIcon />}
                sx={{ display: { md: "flex", lg: "none" } }}
                onClick={() => navigate("/chat")}
              />
            )}

            {(isAuthenticated || error) && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={user ? user.nickname : ""}
                      src={user ? user.picture : "../../img/avatar.jpg"}
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="user-menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                  className="user-menu"
                >
                  <ProfileMenu
                    onClose={handleCloseUserMenu}
                    onLogout={handleLogout}
                    user={user}
                  />
                </Menu>
              </Box>
            )}

            <Box sx={{ flexGrow: 0, display: { md: "flex", lg: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleMenuDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        open={settingsDrawer}
        className="settings-drawer"
        onClose={toggleSettingsDrawer}
        onOpen={toggleSettingsDrawer}
      >
        <List sx={{ width: 300, padding: 0 }}>
          <ListItem disablePadding className="drawer-toggle">
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: settingsDrawer ? "initial" : "center",
                px: 2.5,
              }}
              onClick={toggleSettingsDrawer}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.5,
                }}
              >
                <TuneIcon />
              </ListItemIcon>
              <ListItemText
                className="toggle-icon"
                primary="Chat settings"
                sx={{ opacity: 1 }}
              />
              <ChevronLeftIcon />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ChatSettings open={true} onClose={toggleSettingsDrawer} />
        </List>
      </SwipeableDrawer>
      <SwipeableDrawer
        anchor="right"
        open={menuDrawer}
        onClose={toggleMenuDrawer}
        onOpen={toggleMenuDrawer}
      >
        <List sx={{ width: 300 }}>
          <ListItem sx={{ justifyContent: "end" }}>
            <IconButton onClick={toggleMenuDrawer}>
              <CloseOutlinedIcon />
            </IconButton>
          </ListItem>
          <Divider />
          {(isAuthenticated || error) &&
            authPages.map((page) => (
              <ListItem
                key={page.label}
                onClick={() => navigate(page.path)}
                disablePadding
              >
                <ListItemButton selected={isCurrent(page.path)}>
                  <ListItemText>
                    <Typography>{page.label}</Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          {pages.map((page) => (
            <ListItem
              key={page.label}
              onClick={() => navigate(page.path)}
              disablePadding
            >
              <ListItemButton selected={isCurrent(page.path)}>
                <ListItemText>
                  <Typography>{page.label}</Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </SwipeableDrawer>
    </>
  );
}
export default ResponsiveAppBar;
