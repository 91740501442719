import React from "react";
import "./index.css";
import App from "./App";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import reportWebVitals from "./reportWebVitals";
import { BrainstormProvider } from "./components/utils/BrainstormContext";
import { LensProvider } from "./components/utils/LensContext";
import { ChatProvider } from "./components/utils/ChatContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrainstormProvider>
      <LensProvider>
        <ChatProvider>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <App />
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </ChatProvider>
      </LensProvider>
    </BrainstormProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
