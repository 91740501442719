import { useAuth0 } from "@auth0/auth0-react";
import { Alert, AlertTitle, Box } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SideDrawer from "../components/SideDrawer";
import Header from "../components/header";
import React, { useState } from "react";

const AppLayout = ({ children, className, contentWithTitle, scrollable }) => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { error, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (window.redirectTimeout) clearInterval(window.redirectTimeout);

    if (error || !isAuthenticated) {
      window.redirectTimeout = setTimeout(() => navigate("/"), 6000);
    }
  }, [isAuthenticated, error]);

  const ApprovalPending = () => {
    return (
      <Box className="app-section">
        <Alert severity="error">
          <AlertTitle>Approval is Pending</AlertTitle>
          <p>
            You will recieve a confirmation email from our Login provider Auth0
            and an email from us as soon as a spot opens up!
          </p>
          <p>
            Questions? Email:{" "}
            <a href="mailto:hello@aipriori.com">hello@aipriori.com</a>
          </p>
        </Alert>
      </Box>
    );
  };
  const AccessDenied = () => {
    return (
      <Box className="app-section">
        <Alert severity="error">
          <AlertTitle>Access Denied</AlertTitle>
          Please login first!
        </Alert>
      </Box>
    );
  };

  const ActualContent = () => {
    if (error) return <ApprovalPending />;

    if (!isAuthenticated) return <AccessDenied />;

    return children;
  };

  const useTitle = contentWithTitle && !error && isAuthenticated;

  return (
    <div className={`app-container ${className ? className : ""}`}>
      <Header searchBar />
      <SideDrawer contentWithTitle={useTitle} scrollable={scrollable}>
        <ActualContent />
      </SideDrawer>
    </div>
  );
};

export default AppLayout;
