import React from 'react';
import AppLayout from "../layouts/AppLayout";
import EditLens from "../components/DocChat/EditLens";

const EditLensRoute = () => {
    return (
        <AppLayout scrollable>
            <EditLens />
        </AppLayout>
    );
};

export default EditLensRoute;