import React from "react";

const LensGenerator = ({ lenses, chatHistory }) => {
  const generateLens = () => {
    const definitionLenses = lenses.filter(
      (lens) => lens.lens_type === "Definition"
    );
    const orgGoalsStrategiesLenses = lenses.filter(
      (lens) => lens.lens_type === "Organization Goals and Strategies"
    );
    const priorityAudienceLenses = lenses.filter(
      (lens) => lens.lens_type === "Priority Audience"
    );
    const personaLenses = lenses.filter((lens) => lens.lens_type === "Persona");
    const taskOrientationLenses = lenses.filter(
      (lens) => lens.lens_type === "Task Orientation"
    );
    const contextStoriesLenses = lenses.filter(
      (lens) => lens.lens_type === "Context Stories"
    );

    const definitionPrompts = definitionLenses.map(generateDefinitionLens);
    const orgGoalsStrategiesPrompts = orgGoalsStrategiesLenses.map(
      generateOrgGoalsStrategiesLens
    );
    const priorityAudiencePrompts = priorityAudienceLenses.map(
      generatePriorityAudienceLens
    );
    const personaPrompts = personaLenses.map(generatePersonaLens);
    const taskOrientationPrompts = taskOrientationLenses.map(
      generateTaskOrientationLens
    );

    const contextStoriesPrompts = contextStoriesLenses.map(
      generateContextStoriesLens
    );

    const instructions = [];

    if (definitionPrompts.length > 0) {
      instructions.push(`
        - If the question is related to any of the defined concepts, use the provided definitions, synonyms, and examples to generate a helpful response. If document context is available, use it along with your understanding of the concepts to provide relevant answers.
      `);
    }

    if (orgGoalsStrategiesPrompts.length > 0) {
      instructions.push(`
        - If the question is related to the organization's goals and strategies, ensure that the response aligns with the provided goals, values and strategies. Tie back any response to the organization's stated strategies whenever possible.
      `);
    }

    if (priorityAudiencePrompts.length > 0) {
      instructions.push(`
        - If the question is related to the priority audience, consider the provided audience characteristics, likes, dislikes, and interactions when generating the response. Tailor the response to the specific audience mentioned.
      `);
    }

    if (personaPrompts.length > 0) {
      instructions.push(`
        - When responding to the user's question, assume the role of the specified persona(s) and consider their background, characteristics, values, and communication style. Ensure that your answer is unbiased and does not rely on stereotypes.
      `);
    }

    if (taskOrientationPrompts.length > 0) {
      instructions.push(`
        - If the question is related to a specific task, consider the task definition, desired outcome, challenges, and people involved when generating the response. Provide step-by-step guidance on how to approach and complete the task effectively.
      `);
    }
    if (contextStoriesPrompts.length > 0) {
      instructions.push(`
          - If the question is related to the context stories, consider the perspective, feelings, sentiment, environment, and subtext conveyed within the stories when generating the response. Maintain the same tone and style of language as used in the stories. Ensure that your answer is unbiased and does not rely on stereotypes.
      `);
    }

    instructions.push(`
      - If the user's question is not directly related to ${
        definitionPrompts.length > 0 ? "the defined concepts" : ""
      }${
      definitionPrompts.length > 0 &&
      (orgGoalsStrategiesPrompts.length > 0 ||
        priorityAudiencePrompts.length > 0 ||
        personaPrompts.length > 0 ||
        taskOrientationPrompts.length > 0 ||
        contextStoriesPrompts.length > 0)
        ? ", "
        : ""
    }${
      orgGoalsStrategiesPrompts.length > 0
        ? "the organization's goals and strategies"
        : ""
    }${
      (orgGoalsStrategiesPrompts.length > 0 &&
        priorityAudiencePrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 && personaPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 &&
        taskOrientationPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 && contextStoriesPrompts.length > 0)
        ? ", "
        : ""
    }${
      priorityAudiencePrompts.length > 0
        ? "the priority audience information"
        : ""
    }${
      (priorityAudiencePrompts.length > 0 && personaPrompts.length > 0) ||
      (priorityAudiencePrompts.length > 0 &&
        taskOrientationPrompts.length > 0) ||
      (priorityAudiencePrompts.length > 0 &&
        contextStoriesPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 && personaPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 &&
        taskOrientationPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 && contextStoriesPrompts.length > 0)
        ? ", "
        : ""
    }${personaPrompts.length > 0 ? "the specified persona(s)" : ""}${
      (personaPrompts.length > 0 && taskOrientationPrompts.length > 0) ||
      (personaPrompts.length > 0 && contextStoriesPrompts.length > 0) ||
      (priorityAudiencePrompts.length > 0 &&
        taskOrientationPrompts.length > 0) ||
      (priorityAudiencePrompts.length > 0 &&
        contextStoriesPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 &&
        taskOrientationPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 && contextStoriesPrompts.length > 0)
        ? ", "
        : ""
    }${
      taskOrientationPrompts.length > 0 ? "the specified task orientation" : ""
    }${
      (taskOrientationPrompts.length > 0 && contextStoriesPrompts.length > 0) ||
      (personaPrompts.length > 0 && contextStoriesPrompts.length > 0) ||
      (priorityAudiencePrompts.length > 0 &&
        contextStoriesPrompts.length > 0) ||
      (orgGoalsStrategiesPrompts.length > 0 && contextStoriesPrompts.length > 0)
        ? ", or "
        : ""
    }${
      contextStoriesPrompts.length > 0 ? "the provided context stories" : ""
    }, provide a general response based on the available information.
    `);

    const combinedPrompt = `
    System Prompt:
    You are an AI assistant that helps users understand concepts, align responses with organizational goals, generate responses tailored to priority audiences, assume the role of specified personas, provide guidance on specific tasks, and consider context stories based on the following information:
  
    ${definitionPrompts.map((prompt) => prompt.definitionText).join("\n")}
  
    ${orgGoalsStrategiesPrompts
      .map((prompt) => prompt.goalsStrategiesText)
      .join("\n")}
  
    ${priorityAudiencePrompts
      .map((prompt) => prompt.priorityAudienceText)
      .join("\n")}
  
    ${personaPrompts.map((prompt) => prompt.personaText).join("\n")}
  
    ${taskOrientationPrompts
      .map((prompt) => prompt.taskOrientationText)
      .join("\n")}
  
    ${contextStoriesPrompts
      .map((prompt) => prompt.contextStoriesText)
      .join("\n")}
  
    Instructions:
    When responding to the user's question, follow these guidelines:
    ${instructions.join("\n")}
  
    ${
      definitionPrompts.length > 0 ||
      orgGoalsStrategiesPrompts.length > 0 ||
      priorityAudiencePrompts.length > 0 ||
      personaPrompts.length > 0 ||
      taskOrientationPrompts.length > 0 ||
      contextStoriesPrompts.length > 0
        ? `In all cases, aim to provide helpful explanations, examples, and insights that are relevant to the user's question or statement, while considering ${
            definitionPrompts.length > 0 ? "the defined concepts" : ""
          }${
            definitionPrompts.length > 0 &&
            (orgGoalsStrategiesPrompts.length > 0 ||
              priorityAudiencePrompts.length > 0 ||
              personaPrompts.length > 0 ||
              taskOrientationPrompts.length > 0 ||
              contextStoriesPrompts.length > 0)
              ? ", "
              : ""
          }${
            orgGoalsStrategiesPrompts.length > 0
              ? "the organizational context"
              : ""
          }${
            (orgGoalsStrategiesPrompts.length > 0 &&
              priorityAudiencePrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              personaPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              taskOrientationPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              contextStoriesPrompts.length > 0)
              ? ", "
              : ""
          }${
            priorityAudiencePrompts.length > 0
              ? "the priority audience information"
              : ""
          }${
            (priorityAudiencePrompts.length > 0 && personaPrompts.length > 0) ||
            (priorityAudiencePrompts.length > 0 &&
              taskOrientationPrompts.length > 0) ||
            (priorityAudiencePrompts.length > 0 &&
              contextStoriesPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              personaPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              taskOrientationPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              contextStoriesPrompts.length > 0)
              ? ", "
              : ""
          }${
            personaPrompts.length > 0
              ? "the perspective of the specified persona(s)"
              : ""
          }${
            (personaPrompts.length > 0 && taskOrientationPrompts.length > 0) ||
            (personaPrompts.length > 0 && contextStoriesPrompts.length > 0) ||
            (priorityAudiencePrompts.length > 0 &&
              taskOrientationPrompts.length > 0) ||
            (priorityAudiencePrompts.length > 0 &&
              contextStoriesPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              taskOrientationPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              contextStoriesPrompts.length > 0)
              ? ", "
              : ""
          }${taskOrientationPrompts.length > 0 ? "the task orientation" : ""}${
            (taskOrientationPrompts.length > 0 &&
              contextStoriesPrompts.length > 0) ||
            (personaPrompts.length > 0 && contextStoriesPrompts.length > 0) ||
            (priorityAudiencePrompts.length > 0 &&
              contextStoriesPrompts.length > 0) ||
            (orgGoalsStrategiesPrompts.length > 0 &&
              contextStoriesPrompts.length > 0)
              ? ", and "
              : ""
          }${contextStoriesPrompts.length > 0 ? "the context stories" : ""}.`
        : ""
    }
  
    Chat History:
    ${chatHistory}
  `.trim();

    return {
      prompt: combinedPrompt,
    };
  };

  const generateDefinitionLens = (definition) => {
    const { word_or_phrase, definition: def, synonyms, examples } = definition;
    const synonymsText = synonyms.map((synonym) => `- ${synonym}`).join("\n");
    const examplesText = examples.map((example) => `- ${example}`).join("\n");

    const definitionText = `
      Concept Definition:
      Word or Phrase: ${word_or_phrase}
      Definition: ${def}
      Synonyms:
      ${synonymsText}
      Examples:
      ${examplesText}
    `;

    return {
      definitionText,
    };
  };

  const generateOrgGoalsStrategiesLens = (orgGoalsStrategiesData) => {
    const { lensName, goals, values, strategies } = orgGoalsStrategiesData;

    const goalsStrategiesText = `
      Organizational Goals and Strategies:
      Goals: ${goals}
      Values: ${values}
      Strategies: ${strategies}
    `;

    return {
      goalsStrategiesText,
    };
  };

  const generatePriorityAudienceLens = (priorityAudienceData) => {
    const {
      audienceName,
      audienceDescription,
      audienceCharacteristics,
      audienceLikes,
      audienceDislikes,
      audienceInteractions,
    } = priorityAudienceData;

    const priorityAudienceText = `
      Priority Audience Information:
      Audience Name: ${audienceName}
      Audience Description: ${audienceDescription}
      Audience Characteristics: ${audienceCharacteristics}
      Audience Likes: ${audienceLikes}
      Audience Dislikes: ${audienceDislikes}
      Interactions with Organization: ${audienceInteractions}
    `;

    return {
      priorityAudienceText,
    };
  };

  const generatePersonaLens = (personaData) => {
    const {
      personaName,
      personaDescription,
      personaBackground,
      personaCharacteristics,
      personaValues,
      communicationStyle,
      scenarioContext,
      personaDialogue,
    } = personaData;

    const personaText = `
      Persona:
      Persona Name: ${personaName}
      Persona Description: ${personaDescription}
      Background: ${personaBackground}
      Characteristics: ${personaCharacteristics}
      Values: ${personaValues}
      Communication Style: ${communicationStyle}
      Scenario/Context: ${scenarioContext}
      Persona Dialogue: ${personaDialogue}
    `;

    return {
      personaText,
    };
  };

  const generateTaskOrientationLens = (taskOrientationData) => {
    const {
      lensName,
      taskDefinition,
      desiredOutcome,
      challenges,
      peopleInvolved,
    } = taskOrientationData;

    const taskOrientationText = `
      Task Orientation:
      Lens Name: ${lensName}
      Task Definition: ${taskDefinition}
      Desired Outcome: ${desiredOutcome}
      Challenges: ${challenges}
      People Involved: ${peopleInvolved}
    `;

    return {
      taskOrientationText,
    };
  };
  const generateContextStoriesLens = (contextStoriesData) => {
    const {
      lensName,
      perspective,
      contextStory1,
      contextStory2,
      contextStory3,
    } = contextStoriesData;

    const contextStoriesText = `
    Context Stories Lens:
    Lens Name: ${lensName}
    Perspective: ${perspective}
    Context Story 1: ${contextStory1}
    Context Story 2: ${contextStory2}
    Context Story 3: ${contextStory3}
  `;

    return {
      contextStoriesText,
    };
  };

  return generateLens();
};

export default LensGenerator;
