import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import GridOnIcon from '@mui/icons-material/GridOn';
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box, Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider, FormControlLabel, IconButton, Tab, Tabs, Typography
} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect } from "react";
import { useParams, useLocation } from 'react-router-dom';
import LensCard from '../components/Lenses/LensCard';
import lensesData from "../data/lenses_feed_example.json";
import AppLayout from "../layouts/AppLayout";
import "../styles/Profile.scss";

const lensTest = {
  title: 'This is the title of the Lens, which can vary in length but the design should adjust to it doesn’t matter how long it is. ',
  excerpt: 'This is a small description of what this lens should be used for. Nothing to complicated and straight to the point. Nonetheless, the users are always pushing the boundaries of the product, so we have to make sure the layout doesn’t break when the description of the lens exceeds a certain amount of lines. You can always truncate the text beyond a point that you think is correct.',
  authors: 'Emily Yu, Bryan Speelman + 4',
  published: '2024-04-13',
  likes: 5,
  files: [
    'Healthy Housing.doc',
    'FairHousing2023.pdf',
    'CollaborationHousin...',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
    '2010_2020RentalDataasd',
  ],
  id: '1234',
  version: '2.2',
  type: "personal",
  status: "publish",
}

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
    },
  },
}));

const LensLibrary = ({section}) => {

  const [lenses, setLenses] = React.useState(lensesData)
  const [filteredLenses, setFilteredLenses] = React.useState(lensesData)
  const [bestMatches, setBestMatches] = React.useState([])
  const [currentSection, setCurrentSection] = React.useState(section)

  const getSectionIndex = (sectionName) =>{
    if(sectionName == 'draft')
        return 4

    if(sectionName == 'personal')
      return 2

    return 0
  }
  const location = useLocation();
  React.useEffect(() => {
      setCurrentTab(getSectionIndex(section))
  }, [location]);

  const [currentTab, setCurrentTab] = React.useState(getSectionIndex(section));

  

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const types = [
    'All types',
    'Personal',
    'Community'
  ]

  const sortings = {
    'All types': [
      'Popularity (likes)',
      'Date (latest/oldest)',
      'Popularity (remixed)',
      'Bookmarked',
      'Drafts',
      'On review',
      'Denied',
    ],
    'Personal': [
      'Popularity (likes)',
      'Date (latest/oldest)',
      'Bookmarked',
      'Popularity (remixed)',
      'Drafts',
      'On review',
      'Denied',
    ],
    'Community': [
      'All',
      'Popularity (likes)',
      'Date (latest/oldest)',
      'Popularity (remixed)'
    ]
  }

  const [type, setType] = React.useState('All types');

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const [sorting, setSorting] = React.useState('');

  const [view, setView] = React.useState('grid');
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleSortingChange = (event) => {
    setSorting(event.target.value);
  };

  const handleViewChange = (val) => {
    setView(val);
  }

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = (lens) => {
    setOpenDialog(true);
  };


  useEffect(() => {
    setBestMatches(
      filteredLenses
        .slice(0, 3)
    )
  }, [filteredLenses])

  useEffect(() => {
    setBestMatches(
      filteredLenses
        .sort(() => 0.5 - Math.random())
        .slice(0, 3)
    )
  }, [])

  useEffect(() => {
    let newLenses = lenses.filter((lens) => true)
    if (type !== 'All types') {
      newLenses = newLenses.filter((lens) => lens.type === type.toLowerCase())
    }
   
    if (sorting == 'Popularity (likes)' || sorting == 'Popularity (remixed)') {
      newLenses = newLenses.sort((a, b) =>  b.likes - a.likes)
    } else if(sorting == 'Date (latest/oldest)') {
      newLenses = newLenses.sort((a, b) => new Date(b.published) - new Date(a.published));
    } else if(sorting == 'Bookmarked') {
      newLenses = newLenses.filter((lens) => lens.favorited)
    } else if(sorting == 'Drafts') {
      newLenses = newLenses.filter((lens) => lens.status === 'draft')
    } else if(sorting == 'On review') {
      newLenses = newLenses.filter((lens) => lens.status === 'on-review')
    } else if(sorting == 'Denied') {
      newLenses = newLenses.filter((lens) => lens.status === 'denied')
    }

    setFilteredLenses(newLenses)
  }, [type, sorting])

  useEffect(() => {
    let newLenses = lenses

    if (currentTab == 1) { // favorites
      newLenses = newLenses.filter((lens) => lens.favorited )
    } else if (currentTab == 2) { // personal
      newLenses = newLenses.filter((lens) => lens.type === 'personal')
    } else if (currentTab == 3) { // community
      newLenses = newLenses.filter((lens) => lens.type === 'community')
    } else if (currentTab == 4) { // draft
      newLenses = newLenses.filter((lens) => lens.status === 'draft')
    }
    setFilteredLenses(newLenses)
  }, [currentTab])
  

  return (
    <AppLayout contentWithTitle>
      <section className="lenses">
        <h1>Lens Library</h1>
        {/* TABS BAR */}
        <Box sx={{ bgcolor: "background.paper" }} className="lenses-container">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons={false}
            aria-label="scrollable prevent tabs"
          >
            <Tab label="All" />
            <Tab label="Favorites" />
            <Tab label="Personal" />
            <Tab label="Community" />
            <Tab label={`Drafts(${lenses.filter((lens) => lens.status === 'draft').length})`} />
          </Tabs>
        </Box>
        {/* FILTERS BAR */}
        <Box sx={{ bgcolor: "background.paper", padding: '24px 16px' }} className="lenses-filters">
          <div>
            <Search className="search-wrapper">
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search by keyword"
                inputProps={{ 'aria-label': 'search' }}
              />
            </Search>
            <Typography>Filter by</Typography>
            <FormControl fullWidth>
              <InputLabel id="type-select-label">Type</InputLabel>
              <Select
                labelId="type-select-label"
                id="type-select"
                value={type}
                label="Type"
                onChange={handleTypeChange}
              >
                {types.map(type_option => (
                  <MenuItem value={type_option}>{type_option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="sorting-select-label">Sorting</InputLabel>
              <Select
                labelId="sorting-select-label"
                id="sorting-select"
                value={sorting}
                label="Sorting"
                onChange={handleSortingChange}
              >
                {sortings[type].map(sorting_option => (
                  <MenuItem value={sorting_option}>{sorting_option}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography>View</Typography>
            <div className="view-actions">
              <Button className={`${view == 'grid' ? 'active-view' : ''}`} onClick={() => setView('grid')}>
                <GridOnIcon />
              </Button>
              <Button className={`${view == 'list' ? 'active-view' : ''}`} onClick={() => setView('list')}>
                <ListIcon />
              </Button>
            </div>
          </div>
        </Box>
        <Divider />
        {/* LENSES LISTING */}
        <Box sx={{ bgcolor: "background.paper", padding: '32px' }} className="lenses-listing">
          <Typography variant="h6" component="h6">Best matches</Typography>
          <div className={`lenses-grid ${view}`}>
            {bestMatches.map(lensData => (
              <LensCard info={lensData} addToLibrary={handleOpenDialog} />
            ))}
          </div>
        </Box>
        <Box sx={{ bgcolor: "background.paper", padding: '32px' }} className="lenses-listing">
          <Typography variant="h6" component="h6">Latest</Typography>
          <div className={`lenses-grid ${view}`}>
            {filteredLenses.map(lensData => (
              <LensCard info={lensData} addToLibrary={handleOpenDialog} />
            ))}
          </div>
        </Box>
        <Box sx={{ bgcolor: "background.paper", padding: '32px' }} className="results-row pagination">
          <span>
            Rows per page:
          </span>
          <FormControl sx={{ m: 1, minWidth: 90 }} size="small">
            <Select
              id="demo-simple-select"
              value={rowsPerPage}
              label=""
              onChange={handleChange}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </FormControl>
          <span>
            1-10 of 25
          </span>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="pagination previous page">
            <ChevronLeftOutlinedIcon />
          </IconButton>
          <IconButton type="button" sx={{ p: '10px' }} aria-label="pagination next page">
            <ChevronRightOutlinedIcon />
          </IconButton>
        </Box>
      </section>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"The lens has been added to your library and is ready to use."}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControlLabel
              control={
                <Checkbox
                />
              }
              label="Don’t show this message again."
              sx={{ marginRight: 0 }}
            />
          </DialogContentText>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </AppLayout>
  );
};

export default LensLibrary;
