import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, MenuItem } from '@mui/material';
import Menu from "@mui/material/Menu";
import React from 'react';

const MoreOptions = ({ handleDeleteClick, handleEditClick, child }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const onDelete = () => {
        handleDeleteClick(child);
        handleClose();
    };
    
    const onEdit = () => {
        handleEditClick(child);
        handleClose();
    };

    return (
        <div>
            <IconButton
                edge="end"
                aria-label="more options"
                sx={{ padding: "0 4px", height: '32px', marginLeft: '5px' }}
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={onEdit}>
                    <EditIcon sx={{ marginRight: 1 }} />
                    Edit
                </MenuItem>
                <MenuItem onClick={onDelete}>
                    <DeleteIcon sx={{ marginRight: 1 }} />
                    Delete
                </MenuItem>
            </Menu>
        </div>
    );
};

export default MoreOptions;