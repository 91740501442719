import React from "react";
import { FormControl, InputLabel, TextField, Button } from "@mui/material";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

const DefinitionLens = ({
  wordOrPhrase,
  setWordOrPhrase,
  definition,
  setDefinition,
  synonyms,
  setSynonyms,
  examples,
  setExamples,
  activeStep,
  nextStep,
  prevStep,
  onFinish,
  isEditing = false,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {activeStep === 1 && (
        <>
          <p>
            You can share specific words or phrases that are important to you
            when reviewing documents with AI PRIORI by{" "}
            {isEditing ? "editing" : "creating"} your own "Definition Lens." For
            example, if "equity" is a concept and term that you want AI PRIORI
            to consider when reviewing your documents and producing answers to
            your questions - you can {isEditing ? "edit" : "create"} a custom
            "lens" to share your definition of "equity" with AI PRIORI.
          </p>
          <p>
            Simply define the key word or phrase using this form and provide up
            to 5 examples. Examples do not have to be real, however they do have
            to be detailed enough in order to convey the concept you want AI
            PRIORI to recognize. Remember, the more specific you are in your
            responses, the greater likelihood AI PRIORI can refine its response
            based on your lens.
          </p>
          <Typography className="footer-message">
            <p>Please note:</p>
            <p>
              Efficacy of Lenses is dependent on the information used to create
              the lens as well as the subsequent content and questions it is
              used in conjunction with. Lenses are not guaranteed to change AI
              PRIORI's responses.
            </p>
          </Typography>
          <div className="definitions">
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="word-phrase-input">
                Word/Phrase: What is the word or phrase you want to define?
              </InputLabel>
              <TextField
                required
                placeholder="Write here"
                id="word-phrase-input"
                value={wordOrPhrase}
                onChange={(e) => setWordOrPhrase(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="define-phrase-input">
                Define the word or phrase.
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="define-phrase-input"
                value={definition}
                onChange={(e) => setDefinition(e.target.value)}
              />
            </FormControl>
            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="synonyms-input">
                List (and if possible) define similar words and phrases (also
                known as synonyms).
              </InputLabel>
              <TextField
                multiline
                required
                rows={4}
                placeholder="Write here"
                id="synonyms-input"
                value={synonyms.join("\n")}
                onChange={(e) => setSynonyms(e.target.value.split("\n"))}
              />
            </FormControl>
          </div>
          <div className="actions">
            {isEditing ? (
              <>
                <Button onClick={() => navigate("/chat")}>Cancel</Button>
              </>
            ) : (
              <>
                <Button onClick={prevStep}>Back</Button>
              </>
            )}
            <Button variant="contained" onClick={nextStep}>
              Next
            </Button>
          </div>
        </>
      )}
      {activeStep === 2 && (
        <>
          <p>
            Share up to 10 examples, use cases, or stories that represents the
            concept and definition you are using in relation to your
            word/phrase. The more detailed and specific a representation this
            is, the greater likelihood AI PRIORI can refine its response based
            on this lens you have {isEditing ? "edited" : "created"}.
          </p>

          <div className="definitions">
            {Array(5)
              .fill("")
              .map((item, index) => (
                <FormControl key={index} className="top-labeled">
                  <InputLabel shrink htmlFor={`example-${index}-input`}>
                    Example {index + 1}
                  </InputLabel>
                  <TextField
                    multiline
                    rows={4}
                    placeholder="Write here"
                    id={`example-${index}-input`}
                    value={examples[index] || ""}
                    onChange={(e) => {
                      const newExamples = [...examples];
                      newExamples[index] = e.target.value;
                      setExamples(newExamples);
                    }}
                  />
                </FormControl>
              ))}

            <FormControl className="top-labeled">
              <InputLabel shrink htmlFor="additional-examples-input">
                Share any additional Examples here:
              </InputLabel>
              <TextField
                multiline
                rows={4}
                placeholder="Write here"
                id="additional-examples-input"
                onChange={(e) => {
                  const additionalExamples = e.target.value
                    .split("\n")
                    .filter((ex) => ex.trim() !== "");
                  setExamples(
                    [...examples, ...additionalExamples].slice(0, 10)
                  );
                }}
              />
            </FormControl>
          </div>
          <div className="actions">
            <Button onClick={prevStep}>Back</Button>
            <Button variant="contained" onClick={onFinish}>
              {isEditing ? "Update" : "Create"} lens
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default DefinitionLens;
