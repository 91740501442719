import React, { createContext, useState, useContext, useEffect } from "react";
import ApiUtils from "./ApiUtils";

const ChatContext = createContext();

export function useChatContext() {
  return useContext(ChatContext);
}

export function ChatProvider({ children }) {
  const [sessionId, setSessionId] = useState(null);
  const [files, setFiles] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [isNewSession, setIsNewSession] = useState(true);
  const [filesInfo, setFilesInfo] = useState({
    totalFiles: 0,
    uploadedFiles: 0,
    uploadingFiles: false,
  });
  const [accessToken, setAccessToken] = useState(null);

  const startNewChat = () => {
    setSessionId(null);
    setFiles([]);
    setDocuments([]);
    setChatMessages([]);
    setIsNewSession(true);
    setFilesInfo({
      totalFiles: 0,
      uploadedFiles: 0,
      uploadingFiles: false,
    });
  };

  useEffect(() => {
    // Save session data whenever the relevant state variables change
    const saveChatSession = async () => {
      try {
        const sessionData = {
          sessionId,
          documents,
          chatMessages,
          filesInfo,
        };
        await ApiUtils.saveChatSession(sessionData, accessToken);
      } catch (error) {
        console.error("Error saving chat session:", error);
      }
    };
    if (sessionId && accessToken && !isNewSession) {
      saveChatSession();
    }
  }, [sessionId, documents, chatMessages, filesInfo, isNewSession]);

  const loadChatSession = async (sessionId, accessToken) => {
    try {
      const responseData = await ApiUtils.loadChatSession(sessionId, accessToken);
      if (responseData.error) {
        throw new Error(responseData.error);
      }
      setSessionId(sessionId);
      setFiles(responseData.files || []);
      setDocuments(responseData.documents || []);
      setChatMessages(responseData.chatMessages || []);
      setIsNewSession(false);
      setFilesInfo(responseData.filesInfo || {
        totalFiles: 0,
        uploadedFiles: 0,
        uploadingFiles: false,
      });
    } catch (error) {
      console.error("Error loading chat session:", error);
    }
  };

  useEffect(() => {
    // Retrieve data from local storage on component mount
    const storedData = localStorage.getItem("chatData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setSessionId(parsedData.sessionId);
      setFiles(parsedData.files);
      setDocuments(parsedData.documents);
      setChatMessages(parsedData.chatMessages);
      setIsNewSession(parsedData.isNewSession);
      setFilesInfo(parsedData.filesInfo);
    }
  }, []);

  useEffect(() => {
    // Store data in local storage whenever it changes
    const data = {
      sessionId,
      files,
      documents,
      chatMessages,
      isNewSession,
      filesInfo,
    };
    localStorage.setItem("chatData", JSON.stringify(data));
  }, [sessionId, files, documents, chatMessages, isNewSession, filesInfo]);

  const value = {
    sessionId,
    setSessionId,
    files,
    setFiles,
    documents,
    setDocuments,
    chatMessages,
    setChatMessages,
    isNewSession,
    setIsNewSession,
    filesInfo,
    setFilesInfo,
    startNewChat,
    loadChatSession,
    accessToken,
    setAccessToken,
  };

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
}
