import CreateLens from "../components/DocChat/CreateLense";
import AppLayout from "../layouts/AppLayout";

const LensCreate = () => {
    return (
        <AppLayout scrollable>
            <CreateLens />
        </AppLayout>
    )
};

export default LensCreate;