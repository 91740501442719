import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React from 'react';
import '../styles/Faqs.scss';

const Faqs = ({content, title}) => {


    const renderAnswer = (answer) => {
        const lines = answer.split(';');
        return lines
            .map((line, index) =>
                index < lines.length - 1 ? line + '<br>' : line
            )
            .join('');
    };

    return (
        <section className='faqs' >
            <span className="ornament-triangle-1"></span>
            <span className="ornament-circle-1"></span>

            <div className="faqs-container">
                <h2>{title}</h2>
                <div className='faqs-list'>
                    {content.map((faq, index) => (
                        <Accordion key={index} defaultExpanded={index === 0}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index + 1}-content`}
                                id={`panel${index + 1}-header`}
                            >
                                {faq.question}
                            </AccordionSummary>
                            <AccordionDetails>
                                <div
                                    className="faq-answer"
                                    dangerouslySetInnerHTML={{ __html: renderAnswer(faq.answer) }}
                                ></div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>

            </div>
        </section>
    );
};

export default Faqs;
